import React, { useState, useEffect } from 'react';
import { ApiRequestBuilder } from '../../../../../GlobalComponents/Builders';
import { setInitialFormValues } from '../../../../../GlobalComponents/Helpers';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import BulkProductUpdaterJob from './components/BulkProductUpdaterJob';
import BackorderNotificationSurveyJob from './components/BackorderNotificationSurveyJob';
import TimeEntryLocationAdjusterJob from './components/TimeEntryLocationAdjusterJob';
import DataMigrationToolJob from './components/DataMigrationToolJob';
import ProjectImporterJob from './components/ProjectImporterJob';
import CwmCleanerJob from './components/CwmCleanerJob';
import InvoiceEntryAdjusterJob from './components/InvoiceEntryAdjusterJob';
import OrderEntryPortalJob from './components/OrderEntryPortalJob';
import CommissionTrackerJob from './components/CommissionTrackerJob';

const Job = () => {
    
    let { toolId, jobId } = useParams();

    const [renderPage, setRenderPage] = useState(false);
    
    const [jobData, setJobData] = useState([]);
    const [jobSettings, setJobSettings] = useState([]);
    const [connectionData, setConnectionData] = useState([]);

    const { setValue, formState: { errors } } = useForm();

    const getJobData = () => {
        
        ApiRequestBuilder('toolsToolIdJobsJobIdGet', { toolId, jobId })
            .then(function(results) {

                if (results.data)
                {
                    setJobData(results.data)
                    setJobSettings(results.data.settings)
                    setRenderPage(true)
                }
            }
        )
        .then(() => {

            var intialValues = {}
            
            jobSettings.map(setting => {
                intialValues[setting.name] = setting.value
            }); 

            setInitialFormValues(intialValues, setValue)
        })
    }    

    const renderJobPage = (stage) => {
        switch(toolId)
        {
            case 'bulk-product-updater':
                return <BulkProductUpdaterJob jobData={jobData} connectionData={connectionData} jobSettings={jobSettings} getJobData={getJobData}/>
            case 'cwm-cleaner':
                return <CwmCleanerJob jobData={jobData} connectionData={connectionData} jobSettings={jobSettings} getJobData={getJobData}/>
            case 'backorder-notification-survey':
                return <BackorderNotificationSurveyJob jobData={jobData} connectionData={connectionData} jobSettings={jobSettings} getJobData={getJobData}/>
            case 'time-entry-location-adjuster':
                return <TimeEntryLocationAdjusterJob jobData={jobData} connectionData={connectionData} jobSettings={jobSettings} getJobData={getJobData}/>
            case 'invoice-entry-adjuster':
                return <InvoiceEntryAdjusterJob jobData={jobData} connectionData={connectionData} jobSettings={jobSettings} getJobData={getJobData}/>
            case 'order-entry-portal':
                return <OrderEntryPortalJob jobData={jobData} connectionData={connectionData} jobSettings={jobSettings} getJobData={getJobData}/>
            case 'commission-tracker':
                return <CommissionTrackerJob jobData={jobData} connectionData={connectionData} jobSettings={jobSettings} getJobData={getJobData}/>
            case 'data-migration':
                return <DataMigrationToolJob 
                            jobData={jobData} 
                            connectionData={connectionData} 
                            jobSettings={jobSettings} 
                            getJobData={getJobData}
                        />
            case 'project-importer':
                return <ProjectImporterJob 
                            jobData={jobData} 
                            connectionData={connectionData} 
                            jobSettings={jobSettings} 
                            getJobData={getJobData}
                        />
            default:
                return <p>Sorry, we don't have a tool for that yet!</p>
        }
    }

    useEffect(() => {
    
        document.title = 'View Job'

        getJobData()

        ApiRequestBuilder('connectionsGet')
            .then(function(results) {
                results.data && results.data.length > 0 ? setConnectionData(results.data.filter(connection => connection.type !== 'csv')) : null
            }
        )

    }, []);

    return renderPage ? renderJobPage() : <p>Loading...</p>
};

export default Job;