import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ButtonBuilder, ToastBuilder } from '../../../../../../GlobalComponents/Builders';
import { buildOptionsFromArray, capitalizeFirstLetter, groupObjectsByKey, rekeyArray, setInitialFormValues, sortArrayByKey } from '../../../../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, CardHeader, Collapse } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import { Btn } from '../../../../../../AbstractElements';
import { AccountContext } from '../../../../../../GlobalComponents/Authentication/Accounts';
import { Link, Navigate } from 'react-router-dom';
import { XCircle } from 'react-feather'
import moment from 'moment';

const DataMigrationToolJob = ({jobData, getJobData}) => {

    const { userSession } = useContext(AccountContext);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [isOpen, setIsOpen] = useState(0);
    const [isOpen2, setIsOpen2] = useState(0);

    const [renderPage, setRenderPage] = useState(false);

    const [dataPreview, setDataPreview] = useState(' Fetching Data...');

    const [jobProgress, setJobProgress] = useState([]);

    const [jobResources, setJobResources] = useState([]);
    const [jobResourceMappings, setJobResourceMappings] = useState([]);

    const [jobKeyMappings, setJobKeyMappings] = useState([]);
    const [showJobKeyMappings, setShowJobKeyMappings] = useState(false);

    const [jobValueMappings, setJobValueMappings] = useState([]);
    const [showJobValueMappings, setShowJobValueMappings] = useState(false);

    const [selectedResources, setselectedResources] = useState({});
    const [showSelectedResources, setShowSelectedResources] = useState(false);

    const [destinationConnectionKeys, setDestinationConnectionKeys] = useState([]);
    const [allDestinationConnectionKeys, setAllDestinationConnectionKeys] = useState([]);

    const [destinationConnectionValues, setDestinationConnectionValues] = useState([]);
    const [allDestinationConnectionValues, setAllDestinationConnectionValues] = useState([]);

    const [mappingCodes, setMappingCodes] = useState([])
    const [isArchived, setIsArchived] = useState(false)

    const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
    const toggle2 = (id) => (isOpen2 === id ? setIsOpen2(null) : setIsOpen2(id));
    
    const removeMappingOption = {
        '-----'  : [{label: 'Remove Mapping', value: 0, name:'BLANK'}]
    }
    
    const importMappingOption = {
        'System' : [{label: 'Import Value', value: -1, name:'IMPORT'}]
    }

    const handleResourceCreate = (update, identifier, newResource = false) => {

        var updateArgs = {}

        if (newResource)
        {
            updateArgs = {
                isSetupResource: update.newResourceIsSetup,
                serviceId : identifier.service_id,
                resourceName: update.newResourceName,
                connectionId : identifier.id,
                resource: update.newResource,
            }
        }
        else
        {
            Object.keys(update).forEach(item => {
                if (item.includes(identifier))
                {
                    updateArgs.connectionId = identifier
                    updateArgs.resourceId = update['resource' + identifier]
                }
            })
        }
        
        ApiRequestBuilder('jobsJobIdResourcesPost', { jobId: jobData.id }, updateArgs)
            .then(function(results) {
                getResources()
            }
        )
    }

    const handleXMLDownload = (data) => {

        if (data.data)
        {
            const element = document.createElement("a");
            const file = new Blob([data.data], {type: 'xml'});
            element.href = URL.createObjectURL(file);
            element.download = "setup_mapping.xml";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
        else
        {
            ToastBuilder('error', 'We are unable to provide you with an export')
        }
    }

    const handleResourceDelete = (data, connectionId) => {

        ApiRequestBuilder('jobsJobIdResourcesResourceIdDelete', { jobId: jobData.id, resourceId: data.resource_id }, {  connectionId: connectionId })
            .then(function(results) {
                getResources()
            }
        )
    }

    const handleJobAction = (action, sourceResourceId, myCallback = null, formData = null, showNotification = true) => {

        ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobData.id, actionName: action }, { sourceResourceId, formData })
            .then(function(results) {
                showNotification ? ToastBuilder('success', 'Action Taken Successfully') : null
                getJobData()
                getResources()
                myCallback ? myCallback(results) : null
            }
        )
    }
    
    const handleResourceMappingCreate = (update) => {

        var updateArgs = {
            notes: update.resourceNotes,
            sourceResourceId: update.sourceResourceToMap,
            destinationResourceId: update.destinationResourceToMap,
            afterCreateScripts: update.afterCreateScripts,
            mappingFilter: update.mappingFilter
        }
        
        ApiRequestBuilder('jobsJobIdResourcesMappingsPost', { jobId: jobData.id }, updateArgs)
            .then(function(results) {
                getResources()
            }
        )
    }

    const handleResourceMappingDelete = (update) => {

        var updateArgs = {
            sourceResourceId: update.source_resource_id,
            destinationResourceId: update.destination_resource_id,
        }
        
        ApiRequestBuilder('jobsJobIdResourcesMappingsDelete', { jobId: jobData.id }, updateArgs)
            .then(function(results) {
                getResources()
            }
        )
    }
    
    const handleKeyCreate = (update, identifier) => {

        ApiRequestBuilder('jobsJobIdResourcesKeysPost', { jobId: jobData.id }, {
            key: update['keyName' + identifier],
            resourceId: identifier,
            connectionId: jobData.source_connection.id
        })
            .then(function(results) {
                setTimeout(() => getResources(), 30000)
            }
        )
    }

    const handleValueCreate = (update, identifier, bulk = false) => {

        if (bulk)
        {
            var updateArgs = {
                resourceId: identifier,
                values: update['valuesList' + identifier],
            }
        }
        else
        {
            var updateArgs = {
                value: update['valueName' + identifier],
                valueId: update['valueId' + identifier],
                resourceId: identifier
            }
        }

        ApiRequestBuilder('jobsJobIdResourcesValuesPost', { jobId: jobData.id }, updateArgs)
            .then(function(results) {
                setTimeout(() => getResources(), 30000)
            }
        )
    }

    const handleJobMappingUpdate = (update, identifier, mappingType, rowData = null) => {

        var serviceId = jobData && jobData.source_connection ? jobData.source_connection.service_id : null
        if (!serviceId) return false

        var updateArgs = {}

        if (mappingType === 'resource')
        {
            Object.keys(update).forEach(item => {
                if (item.includes(identifier))
                {
                    updateArgs.jobId = jobData.id
                    updateArgs.sourceResourceId = rowData.source_resource_id
                    updateArgs.destinationResourceId = rowData.destination_resource_id
                    updateArgs.notes = update['notes' + identifier]
                    updateArgs.afterCreateScripts = update['afterCreateScripts' + identifier]
                    updateArgs.mappingFilter = update['mappingFilter' + identifier]
                }
            })

            ApiRequestBuilder('jobsJobIdResourcesMappingsPatch', { jobId: jobData.id, resourceKeyId: rowData.source_resource_key_id }, updateArgs)
                .then(function(results) {
                    getResourceMappings()
                }
            )
        }
        else if (mappingType === 'key')
        {
            Object.keys(update).forEach(item => {
                if (item.includes(identifier))
                {
                    updateArgs.jobId = jobData.id
                    updateArgs.sourceKey = rowData.source_resource_key_id
                    updateArgs.destinationKey = update['destinationResourceKeyId' + identifier]
                    updateArgs.notes = update['notes' + identifier]
                    updateArgs.status = update['status' + identifier]
                    updateArgs.mappingCode = update['code' + identifier] ? update['code' + identifier] : ''
                    updateArgs.mappingConditions = update['conditions' + identifier] ? update['conditions' + identifier].replace('#', '') : '' 
                    updateArgs.defaultValue = update['defaultValue' + identifier] 
                    updateArgs.onFailure = update['onFailure' + identifier]
                    updateArgs.takeEffectOn = update['takeEffectOn' + identifier]
                }
            })

            ApiRequestBuilder('jobsJobIdKeymappingsResourceKeyIdPatch', { jobId: jobData.id, resourceKeyId: rowData.source_resource_key_id }, updateArgs)
                .then(function(results) {
                    getKeyMappings()
                }
            )

        } else {
            
            Object.keys(update).forEach(item => {
                if (item.includes(identifier))
                {
                    updateArgs.jobId = jobData.id
                    updateArgs.notes = update['notes' + identifier]
                    updateArgs.status = update['status' + identifier]
                    updateArgs.sourceValue = rowData.source_resource_value_id
                    updateArgs.destinationValue = update['destinationResourceValueId' + identifier]
                }
            })

            ApiRequestBuilder('jobsJobIdValuemappingsResourceValueIdPatch', { jobId: jobData.id, resourceValueId: rowData.source_resource_value_id }, updateArgs)
                .then(function(results) {
                    getValueMappings()
                }
            )
        }
    }

    const getJobProgress = () => {

        ApiRequestBuilder('jobsJobIdProgressGet', { jobId: jobData.id })
            .then(function(results) {
                setJobProgress(results.data ? results.data : [])
            }
        )
    }

    const getResources = () => {

        var serviceId = jobData && jobData.source_connection ? jobData.source_connection.service_id : null
        if (!serviceId) return false
        
        ApiRequestBuilder('servicesServiceIdResourcesGet', { serviceId: '1-' + jobData.id})
        .then(function(results) {
            setJobResources(results.data && Object.entries(results.data).length > 0 ? rekeyArray(results.data, 'connection_id') : {})
            
            Array(jobData.source_connection, jobData.destination_connection).forEach((connection, idx) => {
                ApiRequestBuilder('servicesServiceIdResourcesGet', { serviceId: connection.service_id }).then(function(results) {
                    if (results.data)
                    {
                        selectedResources[String(connection.id)] = results.data[0]
                        setselectedResources(selectedResources)
                    }
                })
            })

            if (Object.entries(selectedResources).length > 0) return true

        }).then(function(show = false) {
            if (show) 
            {
                setShowSelectedResources(true) 
                getResourceMappings()
            }
        })
    }

    const getKeyMappings = () => {
        
        ApiRequestBuilder('jobsJobIdKeymappingsGet', { jobId: jobData.id })
        .then(function(results) {
            if (results.data && (Object.entries(results.data).length > 0 || results.data.length > 0))
            {
                setJobKeyMappings(results.data ? results.data : [])
                setShowJobKeyMappings(true)
            }
        })
    }

    const getValueMappings = () => {
        
        ApiRequestBuilder('jobsJobIdValuemappingsGet', { jobId: jobData.id })
        .then(function(results) {

            if (results.data && (Object.entries(results.data).length > 0 || results.data.length > 0))
            {
                setJobValueMappings(results.data)
                setShowJobValueMappings(true)
            }
        })
    }

    const getResourceMappings = () => {
        
        ApiRequestBuilder('jobsJobIdResourcesMappingsGet', { jobId: jobData.id })
        .then(function(results) {
            var jobResourceMappingResults = results.data ? results.data : []
            
            setJobResourceMappings(jobResourceMappingResults)
            return jobResourceMappingResults
        })
        .then(function(jobResourceMappingResults){

            var connectionId = jobData && jobData.destination_connection ? jobData.destination_connection.id : null
            if (!connectionId) return false

            /* GET DESTINATION RESOURCE KEYS FOR MAPPING DROPDOWN */
            ApiRequestBuilder('jobsJobIdResourcesKeysGet', { jobId: jobData.id + '-' + connectionId })
            .then(function(results) {
                if (results.data && Object.entries(results.data).length > 0)
                {
                    var cleanMapping = {}
                    var cleanMappingAll = {}

                    var destinationConnectionKeysByResourceId = groupObjectsByKey(results.data, 'resource_id')
                
                    Object.keys(destinationConnectionKeysByResourceId).forEach((resourceId, index) => {

                        var destinationJobMappingsByResourceId = groupObjectsByKey(jobResourceMappingResults, 'destination_resource_id')

                        if (destinationJobMappingsByResourceId[resourceId])
                        {
                            cleanMapping[resourceId] = {}

                            destinationJobMappingsByResourceId[resourceId].forEach((resourceMap) => {

                                destinationConnectionKeysByResourceId[resourceMap.destination_resource_id] = destinationConnectionKeysByResourceId[resourceMap.destination_resource_id] ? destinationConnectionKeysByResourceId[resourceMap.destination_resource_id] : []
                                
                                cleanMapping[resourceId] = Object.assign({}, cleanMapping[resourceId], buildOptionsFromArray(destinationConnectionKeysByResourceId[resourceMap.destination_resource_id], { label: 'key', value: 'resource_key_id', groupKey: 'name', groupLabel: ''}))
                                cleanMappingAll = Object.assign({}, cleanMappingAll, buildOptionsFromArray(destinationConnectionKeysByResourceId[resourceMap.destination_resource_id], { label: 'key', value: 'resource_key_id', groupKey: 'name', groupLabel: ''}))
                            })
                        }
                        
                        if (index + 1 === Object.keys(destinationConnectionKeysByResourceId).length)
                        {
                            setDestinationConnectionKeys(cleanMapping)
                            setAllDestinationConnectionKeys(cleanMappingAll)
                        }
                    })

                    return true
                }
            })
            .then((getKeyMappingss = false) => { 
                if (getKeyMappingss) getKeyMappings()
            })

            /* GET DESTINATION RESOURCE VALUES FOR MAPPING DROPDOWN */
            ApiRequestBuilder('jobsJobIdResourcesValuesGet', { jobId: jobData.id + '-' + connectionId })
            .then(function(results) {

                if (results.data && Object.entries(results.data).length > 0)
                {
                    var cleanMapping = {}
                    var cleanMappingAll = {}

                    var destinationConnectionValuesByResourceId = groupObjectsByKey(results.data, 'resource_id')
                   
                    Object.keys(destinationConnectionValuesByResourceId).forEach((resourceId, index) => {

                        var destinationJobMappingsByResourceId = groupObjectsByKey(jobResourceMappingResults, 'destination_resource_id')

                        if (destinationJobMappingsByResourceId[resourceId])
                        {
                            cleanMapping[resourceId] = {}

                            destinationJobMappingsByResourceId[resourceId].forEach((resourceMap) => {

                                destinationConnectionValuesByResourceId[resourceMap.destination_resource_id] = destinationConnectionValuesByResourceId[resourceMap.destination_resource_id] ? destinationConnectionValuesByResourceId[resourceMap.destination_resource_id] : []
                                
                                cleanMapping[resourceId] = Object.assign({}, cleanMapping[resourceId], buildOptionsFromArray(destinationConnectionValuesByResourceId[resourceMap.destination_resource_id], { label: 'value', value: 'resource_value_id', groupKey: 'name', groupLabel: ''}))
                                cleanMappingAll = Object.assign({}, cleanMappingAll, buildOptionsFromArray(destinationConnectionValuesByResourceId[resourceMap.destination_resource_id], { label: 'value', value: 'resource_value_id', groupKey: 'name', groupLabel: ''}))
                            })
                        }
                        
                        if (index + 1 === Object.keys(destinationConnectionValuesByResourceId).length)
                        {
                            setDestinationConnectionValues(cleanMapping)
                            setAllDestinationConnectionValues(cleanMappingAll)
                        }
                    })

                    return true
                }
            })
            .then((getValueMappingss = false) => { 
                if (getValueMappingss) getValueMappings()
            })
        })
        .then(() => { 
            setRenderPage(true)
        })
    }

    useEffect(() => {   

        document.title = 'View Job'

        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            ToastBuilder('error', 'You don\'t have access to view this page')
        }
        else
        {
            getResources()
            setIsArchived(jobData.is_archived)
            if (!jobData.is_archived) getJobProgress();

            ApiRequestBuilder('jobsJobIdKeymappingsCodesGet', { jobId: jobData.id })
            .then(function(results) {
                if (results.data)
                {
                    setMappingCodes(buildOptionsFromArray(results.data ? results.data : [], { label: 'code', value:'code_id' }))
                    // For timing reasons, we need a backup call to this function
                    setTimeout(() => getResources(), 3000)
                }
            })
        }

    }, []);

    if (renderPage)
    { 
        return (
            <Fragment>
                <Row>
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <h4>Job Settings</h4>
                                <br/><p>A job connections cannot be changed once selected.</p><br/>
                                {jobData && jobData.name ? (<p><strong>Name: </strong>{' ' + jobData.name}</p>) : ''}
                                {jobData && jobData.source_connection ? (<p><strong>Source: </strong>{' ' + jobData.source_connection.name}</p>) : ''}
                                {jobData && jobData.destination_connection ? (<p><strong>Destination: </strong><span style={{ color: (jobData.is_production ? 'red' : '') }}>{' ' + jobData.destination_connection.name}</span></p>) : ''}
                                {jobData && jobData.test_connection && jobData.test_connection.name ? (<p><strong>Test: </strong><span style={{ color: (jobData.is_production ? '' : 'green') }}>{' ' + jobData.test_connection.name}</span></p>) : ''}
                            </CardBody>
                        </Card>
                    </Col>
                    {showSelectedResources ? 
                        <Col xl="6">
                            <Card>
                                <CardBody>
                                    <h4>Job Actions</h4>
                                    <br/>
                                    {jobData && jobData.is_sync && !isArchived ? <ButtonBuilder label={(jobData.is_enabled ? 'Disable' : 'Enable') + ' Job'} className='btn-secondary m-1' onClick={() => handleJobAction((jobData.is_enabled ? 'disable' : 'enable') + '-sync')}/> : null}
                                    {jobProgress.length > 0 && !isArchived ?
                                        <ModalButtonBuilder title='View Progress' className='btn-info m-1' size='xl'
                                            body={
                                                <Fragment>
                                                    <p>Need more details on the progress?</p> 
                                                    <Link to={process.env.PUBLIC_URL + '/tools/data-migration/jobs/' + String(jobData.id) + '/dmt-search'} target="_blank" >Record Search</Link>
                                                    <br/>
                                                    <TableBuilder
                                                        data = {jobProgress}
                                                        processing = {false}
                                                        pagination = {false}
                                                        title = ''
                                                        columns = {[
                                                            {
                                                                name: 'Resource',
                                                                selector: (row) => row.name,
                                                                sortable: true,
                                                                center: true,
                                                            },
                                                            {
                                                                name: 'Staged',
                                                                selector: (row) => row.staged,
                                                                sortable: true,
                                                                center: true,
                                                            },
                                                            {
                                                                name: 'Complete',
                                                                selector: (row) => row.complete,
                                                                sortable: true,
                                                                center: true,
                                                            }
                                                        ]}
                                                    />
                                                </Fragment>
                                            }
                                        /> : null}

                                    { !isArchived ?
                                        <ModalButtonBuilder title='Promote Job' className='btn-danger m-1'
                                            body={
                                                <Form id='promote-job-form' onSubmit={handleSubmit(() => handleJobAction('promote-job'))}>
                                                    <p>Promoting a job will direct the tool to use production credentials. It will erase all the data from the test migration.</p>
                                                    <br/>
                                                    <p>There is <strong>NO</strong> way to revert this action.</p>
                                                </Form>
                                            }
                                        /> : null }             

                                    { !isArchived ?
                                        <ModalButtonBuilder title='Archive Job' className='btn-danger m-1'
                                            body={
                                                <Form id='archive-job-form' onSubmit={handleSubmit(() => handleJobAction('archive-job', null, () => {
                                                            window.location.reload(true)
                                                        }))}>
                                                    <p>Archiving a job will prevent you from making future edits however, you will still be able to view the contents of this page. Jobs should only be archived once the migration is 100% complete.</p>
                                                    <br/>
                                                    <p>There is <strong>NO</strong> way to revert this action.</p>
                                                </Form>
                                            }
                                        />: null }  

                                        <ModalButtonBuilder title='Delete Job' className='btn-danger m-1'
                                            body={
                                                <Form id='delete-job-form' onSubmit={handleSubmit(() => { 
                                                            handleJobAction('archive-job', null, () => { handleJobAction('delete-job'); window.location.href = process.env.PUBLIC_URL + '/tools/' + jobData.route })})
                                                        }>
                                                    <p>Deleting a job will delete all data related to it. You should only delete a job if you created it out of error. Otherwise, we suggest that you archive it.</p>
                                                    <br/>
                                                    <p>There is <strong>NO</strong> way to revert this action.</p>
                                                </Form>
                                            }
                                        />
                                </CardBody>
                            </Card>
                        </Col>
                        : null}
                </Row>
                <Row>
                    {showSelectedResources ? Array(jobData.source_connection, jobData.destination_connection).map((connectionData) => {
                        return (
                            <Col sm='6'>
                                <Card>
                                    <CardBody>
                                        <TableBuilder
                                            data = {jobResources[connectionData.id] ? sortArrayByKey(jobResources[connectionData.id].selected_resources, 'name') : []}
                                            processing = {false}
                                            pagination = {true}
                                            actions={ !isArchived ? <Fragment>
                                                <ModalButtonBuilder title='Add Resource' className='btn-primary'
                                                    body={
                                                        <Form id='add-resource-form' onSubmit={handleSubmit((update) => handleResourceCreate(update, String(connectionData.id)))}>
                                                            <RegisteredInputsBuilder
                                                                registrator={register}
                                                                registratorErrors={errors}
                                                                inputs={[
                                                                    {
                                                                        label: 'Resource',
                                                                        type: 'select',
                                                                        size: 12,
                                                                        required: false,
                                                                        options: <FormOptionsBuilder options={ buildOptionsFromArray(selectedResources[connectionData.id] ? sortArrayByKey(selectedResources[connectionData.id].resources.filter(resource => resource.partner_id === null || resource.partner_id === jobData.partner_id), 'name') : [], {label : 'name', value: 'resource_id'}) }/>,
                                                                        name: 'resource' + String(connectionData.id)
                                                                    },
                                                                ]}/>
                                                        </Form>
                                                    }
                                                />
                                                <ModalButtonBuilder title='Create Resource' className='btn-secondary'
                                                    body={
                                                        <Fragment>
                                                            <p>Some resources and their values need to be created manually if the connection doesn't have an endpoint to retrieve specific mapping information. After creating a new resource, add the destination resource that it will be mapped to. The will create the baseline mappings for which you can then edit down below in the mapping tables.</p><br/>
                                                            <Form id='create-resource-form' onSubmit={handleSubmit((update) => handleResourceCreate(update, connectionData, true))}>
                                                                <RegisteredInputsBuilder
                                                                    registrator={register}
                                                                    registratorErrors={errors}
                                                                    inputs={[
                                                                        {
                                                                            label: 'Name',
                                                                            size: 12,
                                                                            name: 'newResourceName'
                                                                        },
                                                                        {
                                                                            label: 'Resource',
                                                                            size: 12,
                                                                            name: 'newResource',
                                                                            helpText: 'API Endpoint or alternate data source (csv)'
                                                                        },
                                                                        {
                                                                            label: 'Is Setup Resource?',
                                                                            type: 'select',
                                                                            size: 12,
                                                                            required: false,
                                                                            options: <FormOptionsBuilder options={buildOptionsFromArray([{id: 0, value: 'No'}, {id: 1, value: 'Yes'}], { label: 'value', value:'id' })}/>,
                                                                            name: 'newResourceIsSetup'
                                                                        }
                                                                    ]}/>
                                                            </Form>
                                                        </Fragment>
                                                    }
                                                />
                                            </Fragment> : null}
                                            subHeader = {true}
                                            columnsToSearch = {['name']}
                                            title = {connectionData.name}
                                            columns = {[
                                                {
                                                    name: 'ID',
                                                    selector: (row) => row.resource_id,
                                                    sortable: true,
                                                    center: true,
                                                    maxWidth: '5%'
                                                },
                                                {
                                                    name: 'Name',
                                                    selector: (row) => row.name,
                                                    sortable: true,
                                                    center: true,
                                                },
                                                {
                                                    name: 'Remove',
                                                    selector: (row) => <XCircle onClick={() => handleResourceDelete(row, connectionData.id)}></XCircle>,
                                                    sortable: true,
                                                    center: true,
                                                    omit: isArchived,
                                                    maxWidth: '17%'
                                                }
                                            ]}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    }): null}
                </Row>
                <Row>
                    <Container>
                        <Card>
                            {showSelectedResources ? 
                                    <CardBody>
                                        <TableBuilder
                                            data = {jobResourceMappings.length > 0 ? sortArrayByKey(jobResourceMappings, 'source_resource_name') : []}
                                            processing = {false}
                                            pagination = {true}
                                            subHeader = {true}
                                            columnsToSearch = {['source_resource_name', 'destination_resource_name']}
                                            title = 'Resource Mapping'
                                            actions={ !isArchived ? <Fragment>
                                                <ModalButtonBuilder title='Add Resource Mapping' className='btn-primary'
                                                    formId = {'add-resource-mapping-form'}
                                                    body={
                                                        <Fragment>
                                                            <Form id={'add-resource-mapping-form'} onSubmit={handleSubmit((update) => handleResourceMappingCreate(update))}>
                                                                <RegisteredInputsBuilder
                                                                    registrator={register}
                                                                    registratorErrors={errors}
                                                                    inputs={[
                                                                        {
                                                                            label: 'Source Resource',
                                                                            type: 'select',
                                                                            size: 12,
                                                                            required: false,
                                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(jobResources[jobData.source_connection.id] ? jobResources[jobData.source_connection.id].selected_resources : [], { label: 'name', value: 'resource_id' })} />,
                                                                            name: 'sourceResourceToMap',
                                                                        },
                                                                        {
                                                                            label: 'Destination Resource',
                                                                            type: 'select',
                                                                            size: 12,
                                                                            required: false,
                                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(jobResources[jobData.destination_connection.id] ? jobResources[jobData.destination_connection.id].selected_resources : [], { label: 'name', value: 'resource_id' })} />,
                                                                            name: 'destinationResourceToMap'
                                                                        },
                                                                        {
                                                                            label: 'Filter',
                                                                            size: 12,
                                                                            name: 'mappingFilter',
                                                                            type: 'text'
                                                                        },
                                                                        {
                                                                            label: 'After Create',
                                                                            size: 12,
                                                                            name: 'afterCreateScripts',
                                                                            type: 'text',
                                                                            helpText: 'Take some action after resource is processed'
                                                                        },
                                                                        {
                                                                            label: 'Notes',
                                                                            size: 12,
                                                                            name: 'resourceNotes',
                                                                            type: 'textarea'
                                                                        }
                                                                    ]}/>
                                                            </Form>
                                                        </Fragment>
                                                    }
                                                />
                                                <ButtonBuilder label='Refresh All' className='btn-secondary' onClick={() => jobResourceMappings.forEach(resource => handleJobAction('refresh-resource-keys', Number(resource.source_resource_id), getResourceMappings, null, false))}/>
                                            </Fragment> : null }
                                            columns = {[
                                                {
                                                    name: 'Source Resource',
                                                    selector: (row) => row.source_connection_log.log_success !== undefined ? 
                                                        (row.source_connection_log.log_success === 1 ? row.source_resource_name 
                                                            : <span style={{color:'red'}} title={moment.utc(row.source_connection_log.log_requested_at * 1000).fromNow() + '\n' + row.source_connection_log.log_response}>{row.source_resource_name}</span>) 
                                                        : <span title={'Please "Refresh All" if no data is shown for this resource'}>{row.source_resource_name}</span>,
                                                    sortable: true,
                                                    center: true
                                                },
                                                {
                                                    name: 'Destination Resource',
                                                    selector: (row) => row.destination_connection_log.log_success !== undefined ? 
                                                        (row.destination_connection_log.log_success === 1 ? row.destination_resource_name 
                                                            : <span style={{color:'red'}} title={moment.utc(row.destination_connection_log.log_requested_at * 1000).fromNow() + '\n' + row.destination_connection_log.log_response}>{row.destination_resource_name}</span>) 
                                                        : <span title={'Please "Refresh All" if no data is shown for this resource'}>{row.destination_resource_name}</span>,
                                                    sortable: true,
                                                    center: true,
                                                },
                                                {
                                                    name: 'Filter (Hover)',
                                                    selector: (row) => row.mapping_filter ? <span title={JSON.stringify(row.mapping_filter)}>FILTER</span> : '',
                                                    sortable: true,
                                                    center: true,
                                                    maxWidth: '11%'
                                                },
                                                {
                                                    name: 'Notes (Hover)',
                                                    selector: (row) => row.notes ? <span title={JSON.stringify(row.notes)}>NOTES</span> : '',
                                                    sortable: true,
                                                    center: true,
                                                    maxWidth: '11%'
                                                },
                                                {
                                                    name: 'Manage',
                                                    omit: isArchived,
                                                    selector: (row) => <ModalButtonBuilder title='Edit' className='btn-secondary m-1' 
                                                        onClick={setInitialFormValues(row, setValue, 'source_resource_id')}
                                                        onDelete={(data) => handleResourceMappingDelete(row)}
                                                        formId = {'view-/-edit-form' + row.source_resource_id}
                                                        body={
                                                            <Form id={'view-/-edit-form' + row.source_resource_id} onSubmit={handleSubmit(data => handleJobMappingUpdate(data, row.source_resource_id, 'resource', row))}>
                                                                <RegisteredInputsBuilder
                                                                    registrator={register}
                                                                    registratorErrors={errors}
                                                                    inputs={[
                                                                        {
                                                                            label: 'Source Resource',
                                                                            size: 12,
                                                                            name: 'sourceResourceName' + row.source_resource_id,
                                                                            type: 'static'
                                                                        },
                                                                        {
                                                                            label: 'Destination Resource',
                                                                            size: 12,
                                                                            name: 'destinationResourceName' + row.source_resource_id,
                                                                            type: 'static'
                                                                        },
                                                                        {
                                                                            label: 'Filter',
                                                                            size: 12,
                                                                            name: 'mappingFilter' + row.source_resource_id,
                                                                            type: 'text'
                                                                        },
                                                                        {
                                                                            label: 'After Create',
                                                                            size: 12,
                                                                            name: 'afterCreateScripts' + row.source_resource_id,
                                                                            type: 'text',
                                                                            helpText: 'Take some action after resource is processed'
                                                                        },
                                                                        {
                                                                            label: 'Notes',
                                                                            size: 12,
                                                                            name: 'notes' + row.source_resource_id,
                                                                            type: 'textarea'
                                                                        }
                                                                    ]}/>
                                                            </Form>
                                                        }
                                                    />,
                                                    sortable: true,
                                                    center: true,
                                                    maxWidth: '13%'
                                                }
                                            ]}
                                        />
                                    </CardBody>
                                : null}
                        </Card>
                    </Container>
                </Row>
                <Row>
                    <Container>
                        <Card>
                            <CardHeader>
                                <h4>Resource Key Mapping</h4>
                            </CardHeader>
                            <CardBody>
                                <div className="default-according" id="accordion">
                                    {showJobKeyMappings ? Object.entries(groupObjectsByKey(jobKeyMappings, 'source_resource_type')).map((mappingData, idx) => {
                                        return (
                                            <Card>
                                                <CardHeader>
                                                    <Fragment>
                                                        <center>
                                                            <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link col-md-6', color: 'default', onClick: () => toggle(idx + 1) }} >
                                                                {capitalizeFirstLetter(mappingData[0]) + '  ->  ' + capitalizeFirstLetter(mappingData[1][0].destination_resource_type) + ' (Approved - ' + mappingData[1].filter(data => data.status === 'Approved').length + ' / ' + mappingData[1].length + ')'}
                                                            </Btn>
                                                        </center>
                                                    </Fragment>
                                                </CardHeader>
                                                <Collapse isOpen={isOpen === idx + 1}>
                                                <CardBody>
                                                    <TableBuilder
                                                        subHeader = {true}
                                                        columnsToSearch = {['source_key', 'destination_key', 'status']}
                                                        data = {sortArrayByKey(mappingData[1], 'source_key')}
                                                        processing = {false}
                                                        pagination = {false}
                                                        actions={!isArchived ? <Fragment>
                                                            <ModalButtonBuilder title='Add Key' className='btn-primary'
                                                                formId = {'add-key-form' + mappingData[1][0].source_resource_id}
                                                                body={
                                                                    <Fragment>
                                                                        <p>Once the key is created (~30s), the page will refresh to show the new key.</p>
                                                                        <Form id={'add-key-form' + mappingData[1][0].source_resource_id} onSubmit={handleSubmit((update) => handleKeyCreate(update, mappingData[1][0].source_resource_id))}>
                                                                            <RegisteredInputsBuilder
                                                                                registrator={register}
                                                                                registratorErrors={errors}
                                                                                inputs={[
                                                                                    {
                                                                                        label: 'Name',
                                                                                        size: 12,
                                                                                        name: 'keyName' + mappingData[1][0].source_resource_id
                                                                                    }
                                                                                ]}/>
                                                                        </Form>
                                                                    </Fragment>
                                                                }
                                                            />
                                                            <ButtonBuilder label='Refresh Keys' className='btn-secondary' onClick={() => handleJobAction('refresh-resource-keys', mappingData[1][0].source_resource_id)}/>
                                                            <ModalButtonBuilder title='Preview Data' className='btn-info' size='xl' onClick={() => handleJobAction('generate-data-preview', mappingData[1][0].source_resource_id, (results) => { results.data ? setDataPreview(JSON.stringify(results.data.resource_data, null, 4)) : null})}
                                                                body={
                                                                    <Fragment>
                                                                        <p>Below is an example record from the source data:</p>
                                                                        <pre>{dataPreview}</pre>
                                                                    </Fragment>
                                                                }
                                                            />
                                                            <ModalButtonBuilder title='Quick Ignore' className='btn-danger'
                                                                body={
                                                                    <Form id='quick-ignore-form' onSubmit={handleSubmit(() => handleJobAction('quick-ignore', mappingData[1][0].source_resource_id))}>
                                                                        <p>The quick ignore button will set the mapping code to "Ignore" and the status to "Approved" for all unapproved resource key mappings in this section.</p>
                                                                    </Form>
                                                                }
                                                            />
                                                            <ModalButtonBuilder title='Stage Resource' className='btn-danger'
                                                                body={
                                                                    <Form id='stage-resource-form' onSubmit={handleSubmit(() => handleJobAction('stage-resource', mappingData[1][0].source_resource_id))}>
                                                                        <p>Please review the filters defined in the resource mapping before proceeding to stage the resources.</p>
                                                                    </Form>
                                                                }
                                                            />
                                                            <ModalButtonBuilder title='Queue Resource' className='btn-danger'
                                                                body={
                                                                    <Form id='queue-resource-form' onSubmit={handleSubmit((formData) => handleJobAction('queue-resource', mappingData[1][0].source_resource_id, null, formData))}>
                                                                        <p>Please double check your mappings before proceeding to create resources</p>
                                                                        <RegisteredInputsBuilder
                                                                            registrator={register}
                                                                            registratorErrors={errors}
                                                                            inputs={[
                                                                                {
                                                                                    label: 'Limit',
                                                                                    name: 'limit',
                                                                                    type: 'static',
                                                                                    size: 12,
                                                                                    inputSize: 3,
                                                                                    required: false,
                                                                                    value: 10,
                                                                                    helpText: "'IDs' will override limit"
                                                                                },
                                                                                {
                                                                                    label: 'IDs',
                                                                                    name: 'valuesToQueue',
                                                                                    size: 12,
                                                                                    required: false,
                                                                                    placeholder: 'an empty list queues all staged values',
                                                                                    helpText: 'Enter comma delimited list of IDs'
                                                                                },
                                                                                {
                                                                                    label: 'Perform Updates',
                                                                                    name: 'performUpdates',
                                                                                    type: 'select',
                                                                                    size: 12,
                                                                                    required: false,
                                                                                    options: <FormOptionsBuilder options={buildOptionsFromArray([{id: true, value: 'No, don\'t make updates'}, {id: false, value: 'Yes, create/update resources'}], { label: 'value', value:'id' })}/>
                                                                                },
                                                                            ]}/>
                                                                    </Form>
                                                                }
                                                            />
                                                        </Fragment> : null }
                                                        columns = {[
                                                            {
                                                                name: 'Source Key',
                                                                selector: (row) => <span style={{ color:(row.code === 1 || row.code === 8 ? 'grey': '')}}>{row.source_key}</span>,
                                                                sortable: true,
                                                                center: true
                                                            },
                                                            {
                                                                name: 'Destination Key',
                                                                selector: (row) => row.destination_key ? row.destination_key : '-',
                                                                sortable: true,
                                                                center: true,
                                                            },
                                                            {
                                                                name: 'Status',
                                                                selector: (row) => row.status ? row.status : '-',
                                                                sortable: true,
                                                                center: true,
                                                                maxWidth: '11%'
                                                            },
                                                            {
                                                                name: 'Notes',
                                                                selector: (row) => row.notes ? <span title={row.notes}>VIEW</span> : '',
                                                                sortable: true,
                                                                center: true,
                                                                maxWidth: '7%'
                                                            },
                                                            {
                                                                name: 'Updated At',
                                                                selector: (row) => row.updated_at ? moment.utc(row.updated_at * 1000).fromNow() : '-',
                                                                sortable: true,
                                                                center: true,
                                                                maxWidth: '15%'
                                                            },
                                                            {
                                                                name: 'Manage',
                                                                omit: isArchived,
                                                                selector: (row) => <ModalButtonBuilder title='Edit' className='btn-secondary m-1' 
                                                                    onClick={() => setInitialFormValues(row, setValue, 'source_resource_key_id')}
                                                                    formId = {'view-/-edit-form' + row.source_resource_key_id}
                                                                    body={
                                                                        <Form id={'view-/-edit-form' + row.source_resource_key_id} onSubmit={handleSubmit(data => handleJobMappingUpdate(data, row.source_resource_key_id, 'key', row))}>
                                                                            <RegisteredInputsBuilder
                                                                                registrator={register}
                                                                                registratorErrors={errors}
                                                                                inputs={[
                                                                                    {
                                                                                        label: 'Source Key',
                                                                                        size: 12,
                                                                                        name: 'sourceKey' + row.source_resource_key_id,
                                                                                        type: 'static'
                                                                                    },
                                                                                    {
                                                                                        label: 'Destination Key',
                                                                                        size: 12,
                                                                                        type: 'select',
                                                                                        name: 'destinationResourceKeyId' + row.source_resource_key_id,
                                                                                        options: <FormOptionsBuilder options={destinationConnectionKeys[row.destination_resource_id] ? Object.assign(destinationConnectionKeys[row.destination_resource_id], removeMappingOption) : allDestinationConnectionKeys} isGroup={true}/>,
                                                                                    },
                                                                                    {
                                                                                        label: 'Status',
                                                                                        size: 12,
                                                                                        type: 'select',
                                                                                        name: 'status' + row.source_resource_key_id,
                                                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(['Approved', 'Pending Approval', 'Under Review', 'Dev Review'], { labelIsValue: true })}/>
                                                                                    },
                                                                                    {
                                                                                        label: 'Notes',
                                                                                        size: 12,
                                                                                        name: 'notes' + row.source_resource_key_id,
                                                                                        type: 'textarea'
                                                                                    },
                                                                                    {
                                                                                        label: 'Mapping Code',
                                                                                        size: 12,
                                                                                        type: 'select',
                                                                                        name: 'code' + row.source_resource_key_id,
                                                                                        options: <FormOptionsBuilder options={mappingCodes}/>,
                                                                                    },
                                                                                    {
                                                                                        label: 'Mapping Conditions',
                                                                                        size: 12,
                                                                                        type: 'textarea',
                                                                                        name: 'conditions' + row.source_resource_key_id,
                                                                                    },
                                                                                    {
                                                                                        label: 'Default Value',
                                                                                        size: 12,
                                                                                        name: 'defaultValue' + row.source_resource_key_id
                                                                                    },
                                                                                    {
                                                                                        label: 'On Failure',
                                                                                        size: 12,
                                                                                        type: 'select',
                                                                                        name: 'onFailure' + row.source_resource_key_id,
                                                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(['Retry', 'Skip', 'Delete'], { labelIsValue: true })}/>
                                                                                    },
                                                                                    {
                                                                                        label: 'Takes Effect On',
                                                                                        size: 12,
                                                                                        type: 'select',
                                                                                        name: 'takeEffectOn' + row.source_resource_key_id,
                                                                                        helpText: 'When to execute/ignore this mapping',
                                                                                        options: <FormOptionsBuilder options={buildOptionsFromArray([{id: 0, value: 'When creating or updating a record'},{id: 1, value: 'When creating a record'}, {id: 2, value: 'When updating a record'}], { label: 'value', value:'id' })}/>
                                                                                    }
                                                                                ]}/>
                                                                        </Form>
                                                                    }
                                                                />,
                                                                sortable: true,
                                                                center: true,
                                                                maxWidth: '13%'
                                                            },
                                                        ]}
                                                    />
                                                </CardBody>
                                                </Collapse>
                                            </Card>
                                        )
                                    }) : null}
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </Row>
                <Row>
                    <Container>
                        <Card>
                            <CardHeader>
                                    <Row>
                                        <Col sm='10'><h4 className='col-md-6'>Setup Table Mapping</h4></Col>
                                        <ButtonBuilder label={'Export Mapping'} className='btn-secondary col-md-2' onClick={() => handleJobAction('xml-export', null, (data) => handleXMLDownload(data))}/>
                                    </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="default-according" id="accordion">
                                    {showJobValueMappings ? Object.entries(groupObjectsByKey(jobValueMappings, 'source_resource_type')).map((mappingData, idx) => {
                                        
                                        return (
                                            <Card>
                                                <CardHeader>
                                                    <Fragment>
                                                        <center>
                                                            <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link col-md-6', color: 'default', onClick: () => toggle2(idx + 1) }} >
                                                                {capitalizeFirstLetter(mappingData[0]) + '  ->  ' + capitalizeFirstLetter(mappingData[1][0].destination_resource_type) + ' (Approved - ' + mappingData[1].filter(data => data.status === 'Approved').length + ' / ' + mappingData[1].length + ')'}
                                                            </Btn>
                                                        </center>
                                                    </Fragment>
                                                </CardHeader>
                                                <Collapse isOpen={isOpen2 === idx + 1}>
                                                <CardBody>
                                                    <TableBuilder
                                                        subHeader = {true}
                                                        columnsToSearch = {['source_value', 'destination_value', 'status']}
                                                        data = {sortArrayByKey(mappingData[1], 'source_value')}
                                                        processing = {false}
                                                        pagination = {false}
                                                        actions={!isArchived ? <Fragment>
                                                            <ModalButtonBuilder title='Add Value' className='btn-primary'
                                                                formId = {'add-value-form' + mappingData[1][0].source_resource_id}
                                                                body={
                                                                    <Fragment>
                                                                        <p>Once the value is created (~30s), the page will refresh to show the new value.</p>
                                                                        <Form id={'add-value-form' + mappingData[1][0].source_resource_id} onSubmit={handleSubmit((update) => handleValueCreate(update, mappingData[1][0].source_resource_id))}>
                                                                            <RegisteredInputsBuilder
                                                                                registrator={register}
                                                                                registratorErrors={errors}
                                                                                inputs={[
                                                                                    {
                                                                                        label: 'Value',
                                                                                        size: 12,
                                                                                        name: 'valueName' + mappingData[1][0].source_resource_id
                                                                                    },
                                                                                    {
                                                                                        label: 'Rec ID',
                                                                                        size: 12,
                                                                                        inputSize: 5,
                                                                                        type: 'number',
                                                                                        name: 'valueId' + mappingData[1][0].source_resource_id
                                                                                    }
                                                                                ]}/>
                                                                        </Form>
                                                                    </Fragment>
                                                                }
                                                            />
                                                            <ModalButtonBuilder title='Add Values' className='btn-secondary'
                                                                formId = {'add-values-form' + mappingData[1][0].source_resource_id}
                                                                body={
                                                                    <Fragment>
                                                                        <p>Once the values are created (~30s), the page will refresh to show the new values.</p>
                                                                        <Form id={'add-values-form' + mappingData[1][0].source_resource_id} onSubmit={handleSubmit((update) => handleValueCreate(update, mappingData[1][0].source_resource_id, true))}>
                                                                            <RegisteredInputsBuilder
                                                                                registrator={register}
                                                                                registratorErrors={errors}
                                                                                inputs={[
                                                                                    {
                                                                                        label: 'Value',
                                                                                        size: 12,
                                                                                        type: 'textarea',
                                                                                        name: 'valuesList' + mappingData[1][0].source_resource_id,
                                                                                        placeholder: 'RecID,Value\n1,First Value',
                                                                                        helpText: 'Paste in a csv formatted list or direct copy from excel' 
                                                                                    }
                                                                                ]}/>
                                                                        </Form>
                                                                    </Fragment>
                                                                }
                                                            />
                                                            <ButtonBuilder label='Refresh Values' className='btn-info' onClick={() => handleJobAction('refresh-resource-values', mappingData[1][0].source_resource_id)}/>
                                                            
                                                            <ModalButtonBuilder title='Quick Approve' className='btn-danger'
                                                                body={
                                                                    <Form id='quick-approve-form' onSubmit={handleSubmit(() => handleJobAction('quick-approve', mappingData[1][0].source_resource_id))}>
                                                                        <p>The quick approve button will set the status to "Approved" for all unapproved resource value mappings in this section.</p>
                                                                    </Form>
                                                                }
                                                            />

                                                            {/*<ModalButtonBuilder title='Import Values' className='btn-info'
                                                                body={
                                                                    <Form id='stage-resource-form' onSubmit={handleSubmit((update) => handleJobAction('import-resource-values', mappingData[1][0].source_resource_id))}>
                                                                        <RegisteredInputsBuilder
                                                                            registrator={register}
                                                                            registratorErrors={errors}
                                                                            inputs={[
                                                                                {
                                                                                    label: 'Connection',
                                                                                    type: 'select',
                                                                                    size: 12,
                                                                                    name: 'connectionToInsertValues',
                                                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(Array(jobData.source_connection, jobData.destination_connection), { label: 'name', value:'id'})}/>,
                                                                                },
                                                                                {
                                                                                    label: 'Values',
                                                                                    name: 'valuesToInsert',
                                                                                    size: 12,
                                                                                    required: false,
                                                                                    helpText: 'Enter comma delimited list of values'
                                                                                }
                                                                            ]}/>
                                                                    </Form>
                                                                }
                                                            />*/}
                                                        </Fragment> : null}
                                                        columns = {[
                                                            {
                                                                name: 'Source Value',
                                                                selector: (row) => <span title={'REC ID: ' + row.source_value_id + '\nInternal ID: ' + row.source_resource_value_id} style={{ color:(row.code === 1 ? 'grey': '')}}>{row.source_value}</span>,
                                                                sortable: true,
                                                                center: true
                                                            },
                                                            {
                                                                name: 'Destination Value',
                                                                selector: (row) => row.destination_value ? <span title={'REC ID: ' + row.destination_value_id + '\nInternal ID: ' + row.destination_resource_value_id}>{row.destination_value}</span> : '-',
                                                                sortable: true,
                                                                center: true,
                                                            },
                                                            {
                                                                name: 'Status',
                                                                selector: (row) => row.status ? row.status : '-',
                                                                sortable: true,
                                                                center: true,
                                                                maxWidth: '11%'
                                                            },
                                                            {
                                                                name: 'Notes',
                                                                selector: (row) => row.notes ? <span title={row.notes}>VIEW</span> : '',
                                                                sortable: true,
                                                                center: true,
                                                                maxWidth: '7%'
                                                            },
                                                            {
                                                                name: 'Updated At',
                                                                selector: (row) => row.updated_at ? moment.utc(row.updated_at * 1000).fromNow() : '-',
                                                                sortable: true,
                                                                center: true,
                                                                maxWidth: '15%'
                                                            },
                                                            {
                                                                name: 'Manage',
                                                                omit: isArchived,
                                                                selector: (row) => <ModalButtonBuilder title='Edit' className='btn-secondary m-1' 
                                                                    onClick={() => setInitialFormValues(row, setValue, 'source_resource_value_id')}
                                                                    formId = {'view-/-edit-form' + row.source_resource_value_id}
                                                                    body={
                                                                        <Form id={'view-/-edit-form' + row.source_resource_value_id} onSubmit={handleSubmit(data => handleJobMappingUpdate(data, row.source_resource_value_id, 'value', row))}>
                                                                            <RegisteredInputsBuilder
                                                                                registrator={register}
                                                                                registratorErrors={errors}
                                                                                inputs={[
                                                                                    {
                                                                                        label: 'Source Value',
                                                                                        size: 12,
                                                                                        name: 'sourceValue' + row.source_resource_value_id,
                                                                                        type: 'static'
                                                                                    },
                                                                                    {
                                                                                        label: 'Destination Value',
                                                                                        size: 12,
                                                                                        type: 'select',
                                                                                        name: 'destinationResourceValueId' + row.source_resource_value_id,
                                                                                        options: <FormOptionsBuilder options={destinationConnectionValues[row.destination_resource_id] ? Object.assign(destinationConnectionValues[row.destination_resource_id], removeMappingOption) : allDestinationConnectionValues} isGroup={true}/>,
                                                                                    },
                                                                                    {
                                                                                        label: 'Status',
                                                                                        size: 12,
                                                                                        type: 'select',
                                                                                        name: 'status' + row.source_resource_value_id,
                                                                                        options: <FormOptionsBuilder options={buildOptionsFromArray(['Approved', 'Pending Approval', 'Under Review', 'Dev Review'], { labelIsValue: true })}/>
                                                                                    },
                                                                                    {
                                                                                        label: 'Notes',
                                                                                        size: 12,
                                                                                        name: 'notes' + row.source_resource_value_id,
                                                                                        type: 'textarea'
                                                                                    }
                                                                                ]}/>
                                                                        </Form>
                                                                    }
                                                                />,
                                                                sortable: true,
                                                                center: true,
                                                                maxWidth: '13%'
                                                            }
                                                        ]}
                                                    />
                                                    </CardBody>
                                                    </Collapse>
                                                </Card>
                                        )
                                    }) : null}
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
        )
    }
    else 
    {
        if (userSession['custom:partnerId'] !== String(jobData.partner_id) && userSession['custom:partnerId'] !== '0')
        {
            return <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
        }
        return <p>Loading Job Information...</p>
    }
};

export default DataMigrationToolJob;