import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ToastBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import buildRandomId, { buildOptionsFromArray, uploadPresignedFile } from '../../../../../../GlobalComponents/Helpers';
import { Row, Card, CardBody, Form, CardFooter } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Col } from 'react-bootstrap';

const CommissionTrackerJob = ({jobData, getJobData}) => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [salesReps, setSalesReps] = useState([]);
    const [searchQuery, setSearchQuery] = useState({});
    const [orderJobId, setOrderJobId] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const handleCSVDownload = (data) => {

        if (data.data)
        {
            const element = document.createElement("a");
            const file = new Blob([data.data], {type: 'csv'});
            element.href = URL.createObjectURL(file);
            element.download = "import_template.csv";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
        else
        {
            ToastBuilder('error', 'We are unable to provide you with the export')
        }
    }

    const handleDocumentUpload = (file) => {

        if (file['connectionFileToUpload'].length === 0)
        {
            ToastBuilder('error', 'Please Select a File to Upload')
        }
        else
        {
            var fileId = buildRandomId(10);
            
            uploadPresignedFile(file['connectionFileToUpload'], 'filesUploadPost', null, { 
                bucket: process.env.REACT_APP_FILES_BUCKET,
                key: file['connectionFileToUpload'][0].name,
                type: 'jobFile',
                metadata: {
                    jobid: jobData.id,
                    fileid : fileId,
                    fileprefix: 'Commission Import: '
                }
            }, () => { handleJobAction('import-item-commissions', () => {searchCommissions(searchQuery)}, { fileId: fileId})}, { timeout: '5000' }) 
        }
    }

    const handleJobAction = (action, myCallback = null, formData = { 'empty': 'body' }) => {

        ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobData.id, actionName: action }, { ...formData })
            .then(function(results) {
                myCallback ? myCallback(results) : null
            }
        )
    }

    const searchCommissions = (formData) => {
        
        setSearchQuery({})
        setSearchResults([])

        ApiRequestBuilder('oepCommissionsSearchPost', null, formData)
            .then(function(results) {
                if (results.data && results.data.length > 0)
                {
                    setSearchQuery(formData) // We'll store the query to pass to the POST handler if the user wants an export
                    setSearchResults(results.data)
                    ToastBuilder('success', 'Record Search Successful')
                } else ToastBuilder('info', 'No Records Matching Search')

            }
        )
    }

    useEffect(() => {   

        document.title = 'View Job'
        handleJobAction('get-sales-reps', function(reps) { setSalesReps([''].concat(reps.data)) })
        handleJobAction('get-order-job-id', function(order) { setOrderJobId(order.data.order_job_id) })

    }, []);

    return (
        <Fragment>
            <Col xl="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <h4 className='col-md-7'>Search Commission Tracking Module</h4>
                                <ModalButtonBuilder title='Import Commissions' className='btn-secondary col-md-2 m-1' size='lg'
                                    body={
                                        <Fragment>
                                            <p>This importer accepts .xlsx and .csv files containing two columns:</p>
                                                <li>Phone Number</li>
                                                <li>New Payment</li>
                                            <br/>
                                            <Form id='import-commissions-form' onSubmit={handleSubmit(handleDocumentUpload)}>
                                                <RegisteredInputsBuilder
                                                    registrator={register} // phone number as id just to update amount paid
                                                    registratorErrors={errors} // already in system + what comes through excel sheet.
                                                    inputs={[
                                                        {
                                                            label: 'Choose A File',
                                                            size: 12,
                                                            name: 'connectionFileToUpload',
                                                            type: 'file',
                                                        }
                                                    ]}
                                                />
                                            </Form>
                                        </Fragment>
                                    }
                                />
                                <ButtonBuilder 
                                    label={'Back to Orders'} 
                                    className='btn-primary col-md-2 m-1' 
                                    onClick={() => { window.location.href = `${process.env.PUBLIC_URL}/tools/order-entry-portal/jobs/174`}}
                                /> 
                            </Row>
                            <br/>
                            { salesReps.length > 0 ? 
                                <Form id='search-commissions-form'  className='card' onSubmit={handleSubmit((data) => searchCommissions(data))}>
                                    <CardBody>
                                        <Row>
                                        <RegisteredInputsBuilder
                                            registrator={register}
                                            registratorErrors={errors}
                                            inputs={[
                                                {
                                                    label: 'Order #',
                                                    name: 'orderNumber',
                                                },
                                                {
                                                    label: 'Sales Rep',
                                                    type: 'select',
                                                    required: false,
                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(salesReps, { labelIsValue: true })}/>,
                                                    name: 'salesRep'
                                                },
                                                {
                                                    label: 'Sim',
                                                    name: 'sim',
                                                },
                                                {
                                                    label: 'Paid',
                                                    type: 'select',
                                                    required: false,
                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(['', 'Unpaid', 'Partially', 'Fully'], { labelIsValue: true })}/>,
                                                    name: 'paid'
                                                },
                                                {
                                                    label: 'Carrier',
                                                    type: 'select',
                                                    required: false,
                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(['', 'AT&T', 'T-Mobile', 'Verizon'], { labelIsValue: true })}/>,
                                                    name: 'carrier'
                                                },
                                                {
                                                    label: 'Act. Type',
                                                    name: 'activationType',
                                                    type: 'select',
                                                    required: false,
                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(['', 'New', 'Upgrade'], { labelIsValue: true })}/>,
                                                },
                                                {
                                                    label: 'From',
                                                    type: 'date', 
                                                    name: 'searchFrom',
                                                    helpText: 'Activation Date Start'
                                                },
                                                {
                                                    label: 'To',
                                                    type: 'date', 
                                                    name: 'searchTo',
                                                    helpText: 'Activation Date End'
                                                },
                                                {
                                                    label: 'Line Status',
                                                    name: 'lineStatus',
                                                    type: 'select',
                                                    required: false,
                                                    options: <FormOptionsBuilder options={buildOptionsFromArray(['', 'Active', 'Cancelled'], { labelIsValue: true })}/>,
                                                },
                                                {
                                                    label: 'Mobile No.',
                                                    name: 'phoneNumber',
                                                }
                                            ]}/>
                                        
                                        </Row>
                                    </CardBody>
                                    <CardFooter className='text-end'>
                                        <ButtonBuilder form='search-commissions-form' label='Search' className='btn-primary m-2'/>
                                        <ButtonBuilder form='' label='Reset' className='btn-secondary m-1' onClick={() => { 
                                            setValue('orderNumber', '')
                                            setValue('paid', '')
                                            setValue('carrier', '')
                                            setValue('salesRep', '')
                                            setValue('sim', '')
                                            setValue('searchFrom', '')
                                            setValue('paid', '')
                                            setValue('carrier', '')
                                            setValue('activationType', '')
                                            setValue('lineStatus', '')
                                            setValue('searchTo', '')
                                            setValue('phoneNumber', '')
                                        }}/>
                                    </CardFooter>
                                </Form> : null}
                        </CardBody>
                    </Card>
            </Col>
        { searchResults.length > 0 ? 
                <Card>
                    <CardBody>
                        <TableBuilder
                            data = {searchResults}
                            processing = {false}
                            subHeader={true}
                            title='Search Results'
                            columnsToSearch = {['company_name']}
                            actions={
                                <Fragment>
                                    <ButtonBuilder label={'Export'} className='btn-info m-1' onClick={() => handleJobAction('download-commission-template', (data) => handleCSVDownload(data), searchQuery)}/>
                                </Fragment>
                            }
                            columns = {[
                                {
                                    name: 'Status',
                                    selector: (row) => row.paid,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'Expected',
                                    selector: (row) => row.monthly_cost,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'Paid to Date',
                                    selector: (row) => row.amount_received ? row.amount_received : 0,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'Outstanding',
                                    selector: (row) => row.monthly_cost ? row.monthly_cost - row.amount_received : '-',
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'Act Type',
                                    selector: (row) => row.activation_type,
                                    sortable: true
                                },
                                {
                                    name: 'Company',
                                    selector: (row) => row.company_name,
                                    sortable: true
                                },
                                {
                                    name: 'Carrier',
                                    selector: (row) => row.carrier,
                                    sortable: true
                                },
                                {
                                    name: 'Mobile',
                                    selector: (row) => row.phone_number,
                                    sortable: true
                                },
                                {
                                    name: 'MEID/IMEI',
                                    selector: (row) => row.imei,
                                    sortable: true
                                },
                                {
                                    name: 'Sim ID',
                                    selector: (row) => row.sim,
                                    sortable: true
                                },
                                {
                                    name: 'Rate Plan',
                                    selector: (row) => row.rate_plan,
                                    sortable: true
                                },
                                {
                                    name: 'Contract',
                                    selector: (row) => row.contract,
                                    sortable: true
                                },
                                {
                                    name: 'Sales Rep',
                                    selector: (row) => row.sales_rep,
                                    sortable: true
                                },
                                {
                                    name: 'Notes',
                                    selector: (row) => row.notes,
                                    sortable: true,
                                    center: true
                                },
                            ]}
                        />
                    </CardBody>
                </Card>
        : null}
        </Fragment>
    )
};

export default CommissionTrackerJob;