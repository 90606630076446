import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Btn, H3, P } from "../../../AbstractElements";
import { useForm } from 'react-hook-form';
import { Row, Col, Container, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder, FormOptionsBuilder, ButtonBuilder, ModalButtonBuilder } from '../../../GlobalComponents/Builders';
import { buildOptionsFromArray, capitalizeFirstLetter, setInitialFormValues, sortArrayByKey, uploadPresignedFile } from '../../../GlobalComponents/Helpers';

const Connection = () => {

    let { connectionId } = useParams();

    /**
     * Create a global variable to store the original connection data
     * once it is retrieved since the timing of the nested API calls 
     * does not work with the state updates.
     */
    let tempConnectionData = {}

    const [allowUpload, setAllowUpload] = useState(true);
    const [testResults, setTestResults] = useState('Click Test');
    const [connectionData, setConnectionData] = useState(null);
    const [serviceData, setServiceData] = useState(null);
    const [serviceResources, setServiceResources] = useState([]);
    const [spinner, setSpinner] = useState(true);

    const { register, handleSubmit, getValues, setValue, resetField, formState: { errors } } = useForm();
    
    const handleConnectionTest = (formData) => {
        ApiRequestBuilder('connectionsConnectionIdTestPost', { connectionId: connectionData.id }, { resourceToTest: formData.resourceToTest }).then(function(results) {
            if (results.data.response === 'Success!')
            {
                ToastBuilder('success', 'Connection Test Successful')
                setTestResults(<span style={{color:'green'}}>Success!</span>)
            }
            else
            {
                ToastBuilder('error', 'Connection Test Error')

                var error_message_text = 'Failed for an Unknown Reason'
                var error_message = results.data.response

                if (error_message.error)
                {
                    if (error_message.error.message)
                    {
                        error_message_text = error_message.error.message
                    }
                }

                setTestResults(<span style={{color:'red'}}>{error_message_text}</span>)
            }
        })
    }

    const handleDisableConnection = () => {

        ApiRequestBuilder('connectionsConnectionIdDelete', { connectionId : connectionData.id }, {'empty': 'body'}).then(function() {
            window.location.href = process.env.PUBLIC_URL + '/connections/'
        })
    }
    
    const handleDocumentUpload = (file) => {

        if (file['connectionFileToUpload'].length === 0)
        {
            ToastBuilder('error', 'Please Select a File to Upload')
        }
        else
        {
            uploadPresignedFile(file['connectionFileToUpload'], 'filesUploadPost', null, { 
                bucket: process.env.REACT_APP_FILES_BUCKET,
                key: file['connectionFileToUpload'][0].name,
                type: 'connection',
                metadata: {
                    connectionid : connectionData.id
                }
            }, () => { setSpinner(true); getConnectionData()}, { timeout: '5000' }) 
        }
    }

    const onConnectionEdit = (connectionInformation) => {
        ApiRequestBuilder('connectionsConnectionIdPatch', { connectionId }, { ...connectionInformation }).then(function(results) {
            ToastBuilder('success', 'Connection Updated Successfully')
            setTestResults('Click Test')
            getConnectionData()
        })
    }

    const getConnectionData = () => {

        ApiRequestBuilder('connectionsConnectionIdGet', { connectionId })
            .then(function(results) {

                tempConnectionData = results.data
                setConnectionData(results.data)

                /**
                 * If we have a location, don't allow user to overwrite the file
                 */
                results.data.location && results.data.location !== 'None' ? setAllowUpload(false) : null

                ApiRequestBuilder('servicesServiceIdGet', { serviceId: results.data.service_id })
                    .then(function(results) {
                        setServiceData(results.data)
                        setInitialFormValues(tempConnectionData.settings, setValue)
                    }
                )

                ApiRequestBuilder('servicesServiceIdResourcesGet', { serviceId: results.data.service_id }).then(function(results) {
                    if (results.data)
                    {
                        setServiceResources(sortArrayByKey(results.data[0].resources.filter(resource => resource.resource), 'name'))
                    }
                })
            }
        )
    }

    useEffect(() => {

        document.title = 'View Connection'
        getConnectionData()
        
    }, []);

    return (
        <Fragment>
            <Container fluid={true}>
                {serviceData && connectionData ? 
                    <div className="edit-profile">
                        <Row>
                            <Col xl="5">
                                <Form onSubmit={handleSubmit(onConnectionEdit)}>
                                    <Card>
                                        <CardBody>
                                                <Row className="mb-2">
                                                    <div className="profile-title">
                                                        <div className="media">
                                                            <div className="media-body">
                                                                <H3 attrH3={{ className: 'mb-1 f-20 txt-primary' }}>{connectionData.name}</H3>
                                                                <P>{serviceData ? serviceData.name : ''}</P>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Row>
                                                    <RegisteredInputsBuilder 
                                                        registrator={register}
                                                        registratorErrors={errors}
                                                        inputs={
                                                            serviceData ? serviceData.settings.map(setting => {
                                                                return {
                                                                    label: setting.label,
                                                                    size: 12,
                                                                    inputSize: 6,
                                                                    name: setting.name,
                                                                    type: setting.datatype,
                                                                    helpText: setting.helpText
                                                                }
                                                            }
                                                        ) : []}
                                                    />
                                        </CardBody>
                                        <CardFooter className="text-end">
                                            <ButtonBuilder label='Disable Connection' className = 'm-2' color='secondary' onClick={handleDisableConnection}/>                                        
                                            <Btn attrBtn={{ color: "primary", type: "submit" }} >{'Update Connection'}</Btn>    
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                            { connectionData.type !== 'csv' && serviceResources.length > 0 ? 
                            <Col xl="6">
                                <Card>
                                    <CardBody>
                                        <center>
                                            <h4>Connection Status</h4>
                                            <br/>
                                            <h6>{testResults}</h6>
                                            <br/>
                                            <ModalButtonBuilder title='Test Connection' className='btn-primary'
                                                body={
                                                    <Form id='test-connection-form' onSubmit={handleSubmit((formData) => handleConnectionTest(formData))}>
                                                        <p>Use this connection tester to verify we have access to the endpoints required for whatever task(s) will be completed using this connection.</p>
                                                        <br/>
                                                        <RegisteredInputsBuilder
                                                            registrator={register}
                                                            registratorErrors={errors}
                                                            inputs={[
                                                                { 
                                                                    label: 'Resource',
                                                                    type: 'select',
                                                                    size: 12,
                                                                    required: false,
                                                                    options: <FormOptionsBuilder options={ buildOptionsFromArray(serviceResources, {label : 'name', value: 'resource_id'}) }/>,
                                                                    name: 'resourceToTest'
                                                                },
                                                            ]}/>
                                                    </Form>
                                                }
                                            />
                                        </center>
                                    </CardBody>
                                </Card>
                            </Col> : null}
                            {connectionData && connectionData.type === 'csv' ? 
                                <Col xl="5">
                                    <Form onSubmit={handleSubmit(handleDocumentUpload)}>
                                        <Card>
                                            <CardBody>
                                                <Row className="mb-2">
                                                    <div className="profile-title">
                                                        <div className="media">
                                                            <div className="media-body">
                                                                <H3 attrH3={{ className: 'mb-1 f-20 txt-primary' }}>File Upload</H3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Row>
                                                <RegisteredInputsBuilder 
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={
                                                        allowUpload ? [
                                                            {
                                                                label: 'Choose A File',
                                                                size: 12,
                                                                name: 'connectionFileToUpload',
                                                                type: 'file',
                                                            }
                                                        ] : [
                                                            {
                                                                label: 'Connected File',
                                                                size: 12,
                                                                name: 'connectedFile',
                                                                type: 'static',
                                                                value: connectionData.location.split('/')[connectionData.location.split('/').length - 1]
                                                            }
                                                        ]
                                                    }
                                                />
                                            </CardBody>
                                            <CardFooter className="text-end">
                                                {allowUpload ? <ButtonBuilder  className='mb-2' label='Upload File' type='submit' useSpinner={true} /> : null}
                                            </CardFooter>
                                        </Card>
                                    </Form>
                                </Col> : null }
                        </Row>
                    </div> : null }
            </Container>
        </Fragment>
    );
};
export default Connection;