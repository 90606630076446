import React, { Fragment, useState, useEffect, Component } from 'react';
import TableBuilder, { ApiRequestBuilder, FormOptionsBuilder, ToastBuilder, RegisteredInputsBuilder } from '../../GlobalComponents/Builders';
import { buildOptionsFromArray } from '../../GlobalComponents/Helpers';
import { ModalButtonBuilder } from '../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody, Form, NavItem, NavLink, Nav, TabContent, TabPane } from 'reactstrap'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ConnectionList = () => {

    const [connectionData, setConnectionData] = useState([]);
    const [serviceData, setServiceData] = useState([]);

    const [isProcessing, setIsProcessing] = useState(true);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [BasicTab, setBasicTab] = useState('0');

    const tabList = [
        {
            title: 'API',
            filter: 'parameter'
        },
        {
            title: 'CSV',
            filter: 'csv'
        }
    ]

    const handleConnectionCreate = (newConnectionData) => {

        ApiRequestBuilder('connectionsPost', null, newConnectionData).then(function(results){
            if (results.data.id)
            {
                ToastBuilder('success', 'Connection Creation Successful')
                window.location.href = process.env.PUBLIC_URL + '/connections/' + String(results.data.id)
            } 
            else 
            {
                ToastBuilder('error', 'Unable to Create Connection')
            }
        }).catch((err) => {
            console.log(err);
            ToastBuilder('error', 'Unable to Create Connection')
        })
    }

    useEffect(() => {
    
        document.title = 'View Connections'

        ApiRequestBuilder('connectionsGet', null, null, { setIsProcessing })
            .then(function(results) {
                setConnectionData(results.data && results.data.length > 0 ? results.data : [])
            }
        )

        ApiRequestBuilder('servicesGet', null, null, { setIsProcessing })
            .then(function(results) {
                setServiceData(results.data && results.data.length > 0 ? results.data : [])
            }
        )
    }, []);

    return (
            <Fragment>
                <Row>
                    <Container>
                        <Card>
                            <CardBody>
                                <Nav tabs>
                                    { tabList.map((tab, i) => 
                                        <NavItem key={tab.title}>
                                            <NavLink href="#" className={BasicTab === String(i) ? 'active' : ''} onClick={() => setBasicTab(String(i))}>{tab.title}</NavLink>
                                        </NavItem>
                                    )}
                                </Nav>
                                <TabContent activeTab={BasicTab}>
                                    { tabList.map((tab, i) =>
                                        (
                                            <TabPane className="fade show" tabId={String(i)} key={tab.title + i}>
                                                <TableBuilder
                                                    //subHeader={true}
                                                    //columnsToSearch={['name']}
                                                    data={connectionData.filter(connection => connection.type === tab.filter && connection.is_active !== 0)}
                                                    processing={isProcessing}
                                                    actions={<ModalButtonBuilder title='Create Connection' className='btn-secondary'
                                                        body={
                                                            <Form id='create-connection-form' onSubmit={handleSubmit(handleConnectionCreate)}>
                                                                <RegisteredInputsBuilder
                                                                    registrator={register}
                                                                    registratorErrors={errors}
                                                                    inputs={[
                                                                        {
                                                                            label: 'Name',
                                                                            size: 12,
                                                                            required: false,
                                                                            name: 'connectionName'
                                                                        },
                                                                        {
                                                                            label: 'Description',
                                                                            size: 12,
                                                                            required: false,
                                                                            name: 'description'
                                                                        },
                                                                        {
                                                                            label: 'Service',
                                                                            type: 'select',
                                                                            size: 12,
                                                                            required: false,
                                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(serviceData, {label : 'name', value: 'id'})}/>,
                                                                            name: 'service'
                                                                        }
                                                                    ]}/>
                                                            </Form>
                                                        }
                                                    />}
                                                    title = 'Connections'
                                                    columns = {[
                                                        {
                                                            name: 'Name',
                                                            selector: (row) => row.name,
                                                            sortable: true,
                                                            center: true,
                                                        },
                                                        {
                                                            name: 'Service',
                                                            selector: (row) => row.service,
                                                            sortable: true,
                                                            center: true,
                                                        },
                                                        {
                                                            name: 'Created At',
                                                            selector: (row) => row.created_at ? moment.utc(row.created_at * 1000).format('l') : '-',
                                                            sortable: true,
                                                            center: true,
                                                        },
                                                        {
                                                            name: 'Manage',
                                                            selector: (row) => <Link to={`${process.env.PUBLIC_URL}/connections/` + row.guid}>View / Edit</Link>,
                                                            sortable: true,
                                                            center: true,
                                                        },
                                                    ]}
                                                />
                                            </TabPane>
                                        )
                                    )}   
                                </TabContent>
                                
                            </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
    );
};

export default ConnectionList;