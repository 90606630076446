import React, { Fragment, useState, useEffect } from 'react';
import TableBuilder, { ApiRequestBuilder, ModalButtonBuilder, ModalLinkBuilder, TilesBuilder } from '../../GlobalComponents/Builders';
import { Container, Row, Card, Col, CardBody, CardHeader, Collapse } from 'reactstrap'
import CountUp from 'react-countup';
import { groupObjectsByKey, capitalizeFirstLetter, showRelativeTime } from '../../GlobalComponents/Helpers';
import { Btn } from '../../AbstractElements';
import { ContentLoaderInput } from '../../Layout/Loader';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';

const ProjectList = () => {

    const [isProcessing, setIsProcessing] = useState(true);
    const [projectData, setProjectData] = useState([]);
    const [monthlyTimeRemaining, setMonthlyTimeRemaining] = useState([]);
    const [monthlyRecurringCost, setMonthlyRecurringCost] = useState([]);
    const [monthlyRecurringItems, setMonthlyRecurringItems] = useState([]);
    const [usedHoursDescriptions, setUsedHoursDescriptions] = useState([]);

    const [isOpen, setIsOpen] = useState(0);

    const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
        
    const tiles = [
        {
            id: 1,
            icon: 'ShoppingBag',
            title: 'Hours Remaining',
            footNote: `Ten Hour Monthly Agreement`,
            content: <ModalLinkBuilder
                title='Hour Usage'
                size='xl'
                customLabel={<CountUp className='tile-counter' delay={2} start={10} end={monthlyTimeRemaining} duration={7} useEasing={true} />}
                onSubmit={false}
                onCancelLabel='Close'
                body={
                    <Card>
                        <CardBody>
                            <TableBuilder
                                data={usedHoursDescriptions}
                                title=''
                                pagination={false}
                                processing={false}
                                columns={[
                                    {
                                        name: 'Dev',
                                        selector: (row) => capitalizeFirstLetter(row.enteredBy),
                                        sortable: true,
                                        center: true,
                                        maxWidth: '8%'
                                    },
                                    {
                                        name: 'Hours',
                                        selector: (row) => row.actualHours,
                                        sortable: true,
                                        center: true,
                                        maxWidth: '5%'
                                    },
                                    {
                                        name: 'Notes',
                                        selector: (row) => row.notes,
                                        sortable: true,
                                        center: false,
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>

                }
            />,
        },
        {
            id: 2,
            icon: 'ShoppingBag',
            title: 'Number of Projects',
            count: <CountUp start={0} end={projectData.length} duration={6} useEasing={true} />,
        },
        {
            id: 3,
            icon: 'ShoppingBag',
            title: 'Recurring Invoice Total',
            count: <CountUp start={0} end={monthlyRecurringCost} duration={7} useEasing={true} />,
        },
    ]

    useEffect(() => {
                
        document.title = 'View Projects'

        ApiRequestBuilder('developmentProjectsGet', null, null, { setIsProcessing })
            .then(function(results) {

                if (results.data)
                {
                    setUsedHoursDescriptions(results.data.usedHoursDescriptions)
                    setMonthlyRecurringCost(results.data.monthlyRecurringCost)
                    setMonthlyRecurringItems(results.data.monthlyRecurringItems)
                    setMonthlyTimeRemaining(results.data.remainingHours)
                    setProjectData(results.data.tickets)
                }
            }
        )
    }, []);

    return (
            <Fragment>
                <ContentLoaderInput isLoading={isProcessing} />
                <Container fluid={true}>
                    <TilesBuilder data={tiles}/>
                </Container>
                <Row>
                    <Container>
                        <Card>
                        <CardBody>
                            <h4>Project List</h4><br/>
                            <div className="default-according" id="accordion">
                            {Object.entries(groupObjectsByKey(projectData, 'status')).map((mappingData, idx) => {
                                return (
                                    <Card key={idx + 1}>
                                        <CardHeader>
                                            <Fragment>
                                                <center>
                                                    <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link col-md-3', color: 'default', onClick: () => toggle(idx + 1) }} >
                                                        {capitalizeFirstLetter(mappingData[0]) + ' (' + mappingData[1].length + ')'}
                                                    </Btn>
                                                </center>
                                            </Fragment>
                                        </CardHeader>
                                        <Collapse isOpen={isOpen === idx + 1}>
                                        <CardBody>
                                            <TableBuilder
                                                subHeader={true}
                                                columnsToSearch={['client', 'name', 'status']}
                                                data = {mappingData[1]}
                                                title = ''
                                                processing={false}
                                                columns = {[
                                                    {
                                                        name: 'Client',
                                                        selector: (row) => row.client,
                                                        sortable: true,
                                                        center: true,
                                                    },
                                                    {
                                                        name: 'Name',
                                                        selector: (row) => row.name,
                                                        sortable: true,
                                                        center: true,
                                                    },
                                                    {
                                                        name: 'Time Spent',
                                                        selector: (row) => row.actualHours,
                                                        sortable: true,
                                                        center: true,
                                                    },
                                                    {
                                                        name: 'Time Allotted',
                                                        selector: (row) => row.budgetHours,
                                                        sortable: true,
                                                        center: true,
                                                    },
                                                    {
                                                        name: '',
                                                        selector: (row) => <ModalButtonBuilder title='View Details' className='btn-secondary m-1'
                                                            body={
                                                                <Fragment>
                                                                <p><b>Client:</b> {row.client}</p>
                                                                <p><b>Name:</b> {row.name}</p>
                                                                <p><b>Last Updated By:</b> {row._info.updatedBy}</p>
                                                                <p><b>Last Updated At:</b> {row._info.lastUpdated.slice(0,10) + ' (' + showRelativeTime(moment(row._info.lastUpdated.slice(0,10)).valueOf()) + ')'}</p>
                                                                <p><b>Project Start:</b> {row._info.dateEntered.slice(0,10) + ' (' + showRelativeTime(moment(row._info.dateEntered.slice(0,10)).valueOf()) + ')'}</p>
                                                                <b>Notes:</b> {row.status === 'In Progress' ? <ReactMarkdown>{row.notes}</ReactMarkdown> : ''}
                                                                </Fragment>
                                                            }
                                                        />,
                                                        sortable: true,
                                                        center: true,
                                                    }
                                                ]}
                                            />
                                        </CardBody>
                                        </Collapse>
                                    </Card>)
                                })}
                            </div>
                        </CardBody>
                        </Card>
                    </Container>
                </Row>
                <Row>
                    <Container>
                        <Card>
                        <CardBody>
                            <TableBuilder
                                subHeader={true}
                                columnsToSearch={['client', 'name', 'status']}
                                data = {monthlyRecurringItems}
                                title = 'Recurring Costs'
                                sortField = {4}
                                processing={false}
                                columns = {[
                                    {
                                        name: 'Client',
                                        selector: (row) => row.invoiceDescription.split('/').slice(0,1),
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Project',
                                        selector: (row) => row.invoiceDescription.split('/').slice(1),
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Price',
                                        selector: (row) => '$' + String(row.unitPrice * row.quantity),
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Billing Start',
                                        selector: (row) => row.effectiveDate.slice(0,10),
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                        </Card>
                    </Container>
                </Row>
            </Fragment>
    );
};

export default ProjectList;