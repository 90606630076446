import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TableBuilder, { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder, ButtonBuilder, FormOptionsBuilder, ModalButtonBuilder } from '../../../../../../../GlobalComponents/Builders';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Form} from 'reactstrap'
import { H4 } from '../../../../../../../AbstractElements';
import buildRandomId, { buildOptionsFromArray, renameKeysCamelToSnake, setInitialFormValues, sortArrayByKey, uploadPresignedFile } from '../../../../../../../GlobalComponents/Helpers';
import { useParams } from 'react-router-dom';

const ItemEntry = () => {

    document.title = 'View Order'
    
    let { jobId, subpage, subpageId } = useParams();

    const [items, setItems] = useState([]);
    const [itemForm, setItemForm] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [renderPage, setRenderPage] = useState(false);
    const [isOrderValid, setIsOrderValid] = useState(false);
    const [makeModelData, setMakeModelData] = useState(false);
    const [ratePlanData, setRatePlanData] = useState(false);
    
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    var orderForm = [
        {
            label: 'Company Name',
            name: 'companyName',
            labelSize: 3,
            inputSize: 6,
            size: 12,
        },
        {
            label: 'Carrier',
            type: 'select',
            labelSize: 3,
            inputSize: 6,
            size: 12,
            required: false,
            options: <FormOptionsBuilder options={buildOptionsFromArray(['AT&T', 'T-Mobile', 'Verizon'], { labelIsValue: true })}/>,
            name: 'carrier'
        },
        {
            label: 'Passcode',
            name: 'passcode',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Account #',
            name: 'accountNumber',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Sim Provided By?',
            type: 'select',
            labelSize: 3,
            inputSize: 6,
            size: 12,
            required: false,
            options: <FormOptionsBuilder options={buildOptionsFromArray(['Carrier','Wireless Support'], { labelIsValue: true })}/>,
            name: 'simProvidedBy'
        },
        {
            label: 'Tax Id',
            name: 'taxId',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Spoc',
            name: 'spoc',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Spoc email',
            name: 'spocEmail',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Associated Name',
            name: 'associatedName',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Associated Email',
            name: 'associatedEmail',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Order By',
            name: 'orderBy',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Shipping Address',
            name: 'shippingAddress', // dropdown - WS address + addresses of sites # TODO
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Shipping Option',
            name: 'shippingOption',
            type: 'select',
            labelSize: 3,
            inputSize: 6,
            size: 12,
            required: false,
            options: <FormOptionsBuilder options={buildOptionsFromArray(['2 Day', 'Overnight (Extra Charge)'], { labelIsValue: true })}/>,
        },
        {
            label: 'Order Emails',
            name: 'orderEmails',
            type: 'textarea',
            helpText: 'Separate emails by (,) comma.',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Order Instructions',
            name: 'orderInstructions',
            type: 'textarea',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Promotion Description',
            name: 'promotionDescription',
            type: 'textarea',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Is Account Clean-Up Needed?',
            name: 'cleanupNeeded',
            type: 'select',
            labelSize: 3,
            inputSize: 4,
            options: <FormOptionsBuilder options={buildOptionsFromArray([{id: 0, value: 'No'}, {id: 1, value: 'Yes (Add details below)'}], { label: 'value', value:'id' })}/>,
            size: 12
        },
        {
            label: 'Account Cleanup Details',
            name: 'cleanupDetails',
            type: 'textarea',
            labelSize: 3,
            inputSize: 6,
            size: 12,
            helpText: 'If applicable.'
        },
        {
            label: 'Activation Type',
            name: 'activationType',
            type: 'select',
            labelSize: 3,
            inputSize: 4,
            options: <FormOptionsBuilder options={buildOptionsFromArray(['New', 'Upgrade'], { labelIsValue: true })}/>,
            size: 12
        },
        {
            label: 'Activation Date',
            name: 'activationDate',
            type: 'date',
            labelSize: 3,
            inputSize: 6,
            size: 12
        },
        {
            label: 'Commission Amount',
            name: 'commissionAmount',
            labelSize: 3,
            inputSize: 3,
            size: 12
        }
    ]

    const validateOrder = () => {

        var errorMessage = ''

        if (orderData.carrier === '' || orderData.carrier === undefined) { errorMessage += '\r\nMissing Data: carrier / ' + orderData.carrier }
        if (orderData.company_name === '' || orderData.company_name === undefined) { errorMessage += '\r\nMissing Data: company name / ' + orderData.company_name }
        if (orderData.account_number === '' || orderData.account_number === undefined) { errorMessage += '\r\nMissing Data: accountNumber / ' + orderData.account_number }

        orderData.line_items.forEach(item => {

            var lineItemErrors = ''

            if (((item.phone_number && !((item.phone_number.length === 10) || (item.phone_number.length === 11 && item.phone_number.slice(0, 1) === '1'))) || !item.phone_number) || !item.phone_number) { lineItemErrors += '\r\nMissing Data: phone number / ' + item.phone_number }
            if ((item.sim && (item.sim.length <= 18 || item.sim.length >= 22)) || !item.sim) { lineItemErrors += '\r\nMissing Data: sim / ' + item.sim }
            if (item.rate_plan === '' || item.rate_plan === undefined) { lineItemErrors += '\r\nMissing Data: rate plan / ' + item.rate_plan }
            if (item.port_in === '' || item.port_in === undefined) { lineItemErrors += '\r\nMissing Data: port in / ' + item.port_in }

            if (lineItemErrors) { errorMessage += '\r\n\r\nItem ' + item.item_id + ':' + lineItemErrors}
        })

        if (errorMessage) { 
            ToastBuilder('error', 'Please fix the following fields:' + errorMessage, { width: '300', textAlign: 'center' }, 5000)
        } else {
            setIsOrderValid(true)
        }
    }

    const handleDocumentUpload = (file) => {

        if (file['connectionFileToUpload'].length === 0)
        {
            ToastBuilder('error', 'Please Select a File to Upload')
        }
        else
        {
            var fileId = buildRandomId(10);

            uploadPresignedFile(file['connectionFileToUpload'], 'filesUploadPost', null, { 
                bucket: process.env.REACT_APP_FILES_BUCKET,
                key: file['connectionFileToUpload'][0].name,
                type: 'jobFile',
                metadata: {
                    jobid: jobId,
                    fileid : fileId,
                    fileprefix: 'Item Import: '
                }
            }, () => { handleJobAction('import-order-items', getItems, { fileId: fileId})}, { timeout: '5000' }) 
        }
    }

    const handleJobAction = (action, myCallback = null, formData = null) => {
        
        ApiRequestBuilder('jobsJobIdActionsActionNamePost', { jobId: jobId, actionName: action }, { ...formData })
            .then(function(results) {
                ToastBuilder('success', 'Action Taken Successfully')
                myCallback ? myCallback(results) : null
            }
        )
    }

    const getMakes = () => {
        return ApiRequestBuilder('integrationsIntegrationNameRequestPost', { integrationName: 'connectwise-manage' }, {
            jobId: jobId,
            method: 'get',
            uri: 'procurement/catalog',
            returnAll: true,
            timeout: 10,
            args: {
                fields: ['id', 'description'],
                conditions: "category/name = 'Carrier Devices' AND inactiveFlag = false"
            }
        })
    }

    const getRatePlans = () => {
        
        ApiRequestBuilder('integrationsIntegrationNameRequestPost', { integrationName: 'connectwise-manage' }, {
            jobId: jobId,
            method: 'get',
            uri: 'procurement/catalog',
            returnAll: true,
            timeout: 10,
            args: {
                fields: ['id', 'description'],
                conditions: "category/name IN ('AT&T Rate Plans', 'T-Mobile Rate Plans', 'Verizon Rate Plans') AND inactiveFlag = false"
            }
        })
        .then(function(results) {
            if (results.data.length > 0)
            {
                var ratePlans = sortArrayByKey(results.data, 'description').map(ratePlan => ratePlan.description)

                setRatePlanData(ratePlans)
                
                getMakes().then(function(results) {
                    if (results.data.length > 0)
                    {
                        var makeModels = sortArrayByKey(results.data, 'description').map(ratePlan => ratePlan.description)
                        
                        setMakeModelData(['BYOD'].concat(makeModels))
                        
                        setItemForm([
                            {
                                label: 'Port In',
                                type: 'select',
                                required: false,
                                options: <FormOptionsBuilder options={buildOptionsFromArray(['No', 'Yes'], { labelIsValue: true })}/>,
                                name: 'portIn'
                            },
                            {
                                label: 'Phone #',
                                name: 'phoneNumber',
                                helpText: 'Only enter Area Code if "N" was selected for Port In'
                            },
                            {
                                label: 'User name',
                                name: 'userName'
                            },
                            {
                                label: 'Contract',
                                type: 'select',
                                required: false,
                                options: <FormOptionsBuilder options={buildOptionsFromArray(['month to month (BYOD)', '24 month DPP', '36 month DPP', '24 month contract', '26 month contract'], { labelIsValue: true })}/>,
                                name: 'contract'
                            },
                            {
                                label: 'Rate plan',
                                type: 'select',
                                required: false,
                                options: <FormOptionsBuilder options={buildOptionsFromArray(ratePlans, { labelIsValue: true })}/>,
                                name: 'ratePlan'
                            },
                            {
                                label: 'Make / Model',
                                type: 'select',
                                required: false,
                                options: <FormOptionsBuilder options={buildOptionsFromArray(makeModels, { labelIsValue: true })}/>,
                                name: 'make'
                            },
                            {
                                label: 'Monthly Cost',
                                placeholder: 'Enter Whole Dollar Amount',
                                name: 'monthlyCost'
                            },
                            {
                                label: 'IMEI',
                                name: 'imei'
                            },
                            {
                                label: 'SIM',
                                name: 'sim'
                            },
                            {
                                label: 'Line notes/instructions:',
                                type: 'textarea',
                                size: 12,
                                labelSize: 3,
                                inputSize: 8,
                                name: 'lineInstructions'
                            }
                        ])
    
                        setRenderPage(true)
                    }
                })
            }
        })
    }

    const submitOrder = () => {
        
        ApiRequestBuilder('oepOrdersOrderIdSubmitPost', { orderId: subpageId })
            .then(function(results) {
                ToastBuilder('success', 'Order Submitted Successfully')
            }
        )

        ApiRequestBuilder('oepOrdersOrderIdPatch', { orderId: subpageId}, { is_submitted: 1 })
            .then(function(results) { 
                    ToastBuilder('success', 'Order Update Successful')
                    getItems()
                }
        )
    }

    const deleteOrder = () => {
        
        ApiRequestBuilder('oepOrdersOrderIdDelete', { orderId: subpageId}, { empty: 'body'})
            .then(function(results) { 
                    window.location.href = `${process.env.PUBLIC_URL}/tools/order-entry-portal/jobs/` + String(jobId)
                }
        )
    }

    const handleCSVDownload = (data) => {

        if (data.data)
        {
            const element = document.createElement("a");
            const file = new Blob([data.data], {type: 'csv'});
            element.href = URL.createObjectURL(file);
            element.download = "import_template.csv";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
        else
        {
            ToastBuilder('error', 'We are unable to provide you with the template')
        }
    }

    const handleOrderUpdate = (orderData) => {

        var cleanOrderData = {};

        orderForm.forEach(input => {
            cleanOrderData[input.name] = orderData[input.name]
        })

        ApiRequestBuilder('oepOrdersOrderIdPatch', { orderId: subpageId}, renameKeysCamelToSnake(cleanOrderData) )
            .then(function(results) { 
                    ToastBuilder('success', 'Order Update Successful')
                    getItems()
                }
        )
    }

    const closeOrder = (data) => {

        var cleanOrderData = {
            orderId : subpageId,
            isClosed : 1,
            activationDate: data.activationDate
        }

        ApiRequestBuilder('oepOrdersOrderIdPatch', { orderId: subpageId}, renameKeysCamelToSnake(cleanOrderData) )
            .then(function(results) { 
                    ToastBuilder('success', 'Order Update Successful')
                    getItems()
                }
        )
    }

    const handleBulkEdit = (itemData) => {
        ToastBuilder('success', 'Item Bulk Update Initiated')
        items.forEach(item => {
            handleItemUpdate(itemData, item.item_id, item)
        })
    }

    const handleItemUpdate = (itemData, identifier, row) => {

        var cleanItemData = { orderId: subpageId };

        itemForm.forEach(input => {
            cleanItemData[input.name] = itemData[input.name + String(row.item_id)]
        })
        
        ApiRequestBuilder('oepOrdersOrderIdItemsItemIdPatch', { orderId: subpageId, itemId: identifier }, renameKeysCamelToSnake(cleanItemData) )
            .then(function(results) {
                getItems()
                ToastBuilder('success', 'Item Update Successful')
            })
    }

    const handleNewItemCreate = (itemData) => {

        var cleanItemData = {};

        cleanItemData.orderId = subpageId

        itemForm.forEach(input => {
            cleanItemData[input.name] = itemData[input.name]
        })
        
        const numberOfItems = Number(itemData['copyToNewLine']) > 0 ? Number(itemData['copyToNewLine']) : 1

        for (let i = 0; i < numberOfItems; i++) {
            ApiRequestBuilder('oepOrdersOrderIdItemsPost', { orderId: subpageId}, renameKeysCamelToSnake(cleanItemData) )
                .then(function(results) { 
                    if (i === numberOfItems - 1 || numberOfItems === 1)
                    {
                        setTimeout(() => {
                            getItems()
                            ToastBuilder('success', 'Item Creation Successful')
                        }, numberOfItems * 1000)
                    }
                }
            )
        }
    }

    const getItems = () => {
        setIsOrderValid(false)
        ApiRequestBuilder('oepOrdersOrderIdGet', { orderId: subpageId})
            .then(function(results) {
                if (results.data)
                {
                    setOrderData(results.data)
                    setItems(results.data.line_items)
                }
            }
        )
    } 
    
    const getBulkEditForm = () => {

        var bulkEditForm = []

        items.forEach(item => {
            bulkEditForm = bulkEditForm.concat([
                {
                    label: 'Port In',
                    type: 'select',
                    labelSize: 3,
                    inputSize: 6,
                    size: 12,
                    required: false,
                    options: <FormOptionsBuilder options={buildOptionsFromArray(['No', 'Yes'], { labelIsValue: true })}/>,
                    name: 'portIn' + item.item_id
                },
                {
                    label: 'Phone #',
                    labelSize: 3,
                    inputSize: 6,
                    size: 12,
                    name: 'phoneNumber' + item.item_id,
                    helpText: 'Only enter Area Code if "N" was selected for Port In'
                },
                {
                    label: 'User name',
                    labelSize: 3,
                    inputSize: 6,
                    size: 12,
                    name: 'userName' + item.item_id
                },
                {
                    label: 'Contract',
                    type: 'select',
                    required: false,
                    labelSize: 3,
                    inputSize: 6,
                    size: 12,
                    options: <FormOptionsBuilder options={buildOptionsFromArray(['month to month (BYOD)', '24 month DPP', '36 month DPP', '24 month contract', '26 month contract'], { labelIsValue: true })}/>,
                    name: 'contract' + item.item_id
                },
                {
                    label: 'Rate plan',
                    type: 'select',
                    required: false,
                    labelSize: 3,
                    inputSize: 6,
                    size: 12,
                    options: <FormOptionsBuilder options={buildOptionsFromArray(ratePlanData, { labelIsValue: true })}/>,
                    name: 'ratePlan' + item.item_id
                },
                {
                    label: 'Make / Model',
                    type: 'select',
                    labelSize: 3,
                    inputSize: 6,
                    size: 12,
                    required: false,
                    options: <FormOptionsBuilder options={buildOptionsFromArray(makeModelData, { labelIsValue: true })}/>,
                    name: 'make' + item.item_id
                },
                {
                    label: 'Monthly Cost',
                    step: 1,
                    labelSize: 3,
                    inputSize: 6,
                    size: 12,
                    placeholder: 'Enter Whole Dollar Amount',
                    name: 'monthlyCost' + item.item_id
                },
                {
                    label: 'IMEI',
                    labelSize: 3,
                    inputSize: 6,
                    size: 12,
                    name: 'imei' + item.item_id
                },
                {
                    label: 'SIM',
                    labelSize: 3,
                    inputSize: 6,
                    size: 12,
                    name: 'sim' + item.item_id
                },
                {
                    label: 'Line Instructions:',
                    type: 'textarea',
                    labelSize: 3,
                    inputSize: 6,
                    size: 12,
                    name: 'lineInstructions' + item.item_id
                },
                {
                    type: 'separator',
                    size: 12,
                    inputSize: 12,
                    name: 'ad'
                }
            ])
        })

        return bulkEditForm
    }

    useEffect(() => {   

        document.title = 'View Order'

        getItems()
        getRatePlans()

    }, []);

    if (renderPage)
    {
        return (
            <Fragment>
                <Row>
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <h4>Order # {orderData.order_number}</h4>
                                Order Type: {orderData.order_type_name}<br/>
                                Carrier: {orderData.carrier}<br/>
                                Company: {orderData.company_name}<br/>
                                <br/><br/>
                                <ButtonBuilder 
                                    label={'Back to Orders'} 
                                    className='btn-primary' 
                                    onClick={() => {window.location.href = `${process.env.PUBLIC_URL}/tools/order-entry-portal/jobs/174`}}
                                />
                                <ButtonBuilder 
                                    label={'View Commissions'} 
                                    className='btn-secondary m-1' 
                                    onClick={() => {window.location.href = `${process.env.PUBLIC_URL}/tools/commission-tracker/jobs/175`}}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <h4>Actions</h4>
                                <br/>
                                <ModalButtonBuilder title='Edit Order' formId='edit-order-form' className='m-1' size='lg'
                                    onClick={() => setInitialFormValues(orderData, setValue)}
                                    body={
                                        <Form id='edit-order-form' onSubmit={handleSubmit((data) => handleOrderUpdate(data))}>
                                            <RegisteredInputsBuilder
                                                registrator={register}
                                                registratorErrors={errors}
                                                inputs={orderForm}
                                            />
                                        </Form>
                                    }
                                />
                                <ModalButtonBuilder title='Import Items' className='btn-secondary m-1' size='lg'
                                    body={
                                        <Fragment>
                                            <Form id='import-items-form' onSubmit={handleSubmit(handleDocumentUpload)}>
                                                <RegisteredInputsBuilder
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={[
                                                        {
                                                            label: 'Choose A File',
                                                            size: 12,
                                                            name: 'connectionFileToUpload',
                                                            type: 'file',
                                                        }
                                                    ]}
                                                />
                                            </Form>
                                        </Fragment>
                                    }
                                />
                                <ButtonBuilder label={'Download Template'} className='btn-info m-1 col-md-4' onClick={() => handleJobAction('download-item-template', (data) => handleCSVDownload(data), {orderId: subpageId})}/>
                                <ModalButtonBuilder title='Submit Order' className='btn-danger m-1' size='lg'
                                    body={
                                        <Form id='submit-order-form' onSubmit={handleSubmit(() => submitOrder())}>
                                            <p>Submitting this order will immediately send an email to the carrier with the details of the order.</p>
                                            <p>Are you sure this order is ready to be submitted?</p>
                                        </Form>
                                    }
                                />
                                <ModalButtonBuilder title='Delete Order' className='btn-danger m-1' size='lg'
                                    body={
                                        <Form id='delete-order-form' onSubmit={handleSubmit(() => deleteOrder())}>
                                            <p>Deleting an order will delete all data related to it. You should only delete an order if you created it out of error or if it was cancelled.</p>
                                            <br/> 
                                            <p>There is <strong>NO</strong> way to revert this action.</p>
                                        </Form>
                                    }
                                />
                                {orderData.is_closed ? null :
                                    !isOrderValid ?
                                    <ButtonBuilder label='Validate Order' className='btn-info m-1' onClick={validateOrder}/> :
                                    <ModalButtonBuilder title='Close Order' className='btn-danger m-1'
                                        body={
                                            <Form id='close-order-form' onSubmit={handleSubmit((data) => closeOrder(data))}>
                                                <RegisteredInputsBuilder
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={[
                                                        {
                                                            label: 'Activation Date',
                                                            size: 12,
                                                            name: 'activationDate',
                                                            type: 'date',
                                                        }
                                                    ]}
                                                />
                                            </Form>
                                    }
                                />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Form id='create-item-form' className='card' onSubmit={handleSubmit((data) => handleNewItemCreate(data))}>
                    <CardHeader className='pb-0'>
                        <H4 attrH4={{ className: 'card-title mb-0' }}>New Item</H4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <RegisteredInputsBuilder
                                registrator={register}
                                registratorErrors={errors}
                                inputs={itemForm}
                            />
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <RegisteredInputsBuilder
                                registrator={register}
                                registratorErrors={errors}
                                inputs={[
                                    {
                                        label: 'Copy to New Line',
                                        size: 9,
                                        inputSize: 2,
                                        labelSize: 4,
                                        type: 'number',
                                        name: 'copyToNewLine'
                                    }
                                ]}
                            />
                            <ButtonBuilder label='Add' className='m-2 col-md-2' form='create-item-form'/>
                        </Row>
                    </CardFooter>
                </Form>
                <Card>
                    <CardBody>
                        <TableBuilder
                            title = 'Line Items'
                            processing={false}
                            data={items}
                            actions={
                                <ModalButtonBuilder title='Bulk Edit' formId='edit-items-form' className='m-2' size='lg'
                                    onClick={() => { setInitialFormValues(items, setValue, 'item_id') }}
                                    body={
                                        <Form id='edit-items-form' onSubmit={handleSubmit((data) => handleBulkEdit(data))}>
                                            <RegisteredInputsBuilder
                                                registrator={register}
                                                registratorErrors={errors}
                                                inputs={getBulkEditForm()}
                                            />
                                        </Form>
                                    }
                                />
                            }
                            columns = {[
                                {
                                    name: 'Manage',
                                    selector: (row) => <ModalButtonBuilder title='Edit' className='btn-secondary m-1' size='lg'
                                        onClick={() => setInitialFormValues(row, setValue, 'item_id')}
                                        formId = {'view-/-edit-form' + row.item_id}
                                        body={
                                            <Form id={'view-/-edit-form' + row.item_id} onSubmit={handleSubmit(data => handleItemUpdate(data, row.item_id, row))}>
                                                <RegisteredInputsBuilder
                                                    registrator={register}
                                                    registratorErrors={errors}
                                                    inputs={[
                                                        {
                                                            label: 'Port In',
                                                            type: 'select',
                                                            size: 10,
                                                            labelSize: 4,
                                                            required: false,
                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(['No', 'Yes'], { labelIsValue: true })}/>,
                                                            name: 'portIn' + row.item_id
                                                        },
                                                        {
                                                            label: 'Phone #',
                                                            name: 'phoneNumber' + row.item_id,
                                                            size: 10,
                                                            labelSize: 4,
                                                            helpText: 'Only enter Area Code if "N" was selected for Port In'
                                                        },
                                                        {
                                                            label: 'User name',
                                                            size: 10,
                                                            labelSize: 4,
                                                            name: 'userName' + row.item_id
                                                        },
                                                        {
                                                            label: 'Contract',
                                                            type: 'select',
                                                            size: 10,
                                                            labelSize: 4,
                                                            required: false,
                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(['month to month (BYOD)', '24 month DPP', '36 month DPP', '24 month contract', '26 month contract'], { labelIsValue: true })}/>,
                                                            name: 'contract' + row.item_id
                                                        },
                                                        {
                                                            label: 'Rate plan',
                                                            type: 'select',
                                                            size: 10,
                                                            labelSize: 4,
                                                            required: false,
                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(ratePlanData, { labelIsValue: true })}/>,
                                                            name: 'ratePlan' + row.item_id
                                                        },
                                                        {
                                                            label: 'Make / Model',
                                                            type: 'select',
                                                            name: 'make' + row.item_id,
                                                            size: 10,
                                                            labelSize: 4,
                                                            required: false,
                                                            options: <FormOptionsBuilder options={buildOptionsFromArray(makeModelData, { labelIsValue: true })}/>,
                                                        },
                                                        {
                                                            label: 'Monthly Cost',
                                                            type: 'number',
                                                            size: 10,
                                                            labelSize: 4,
                                                            placeholder: 'Enter Whole Dollar Amount',
                                                            name: 'monthlyCost' + row.item_id,
                                                            /*registrationSettings: {
                                                                pattern: /^\d+$/
                                                            }*/
                                                        },
                                                        {
                                                            label: 'IMEI',
                                                            size: 10,
                                                            labelSize: 4,
                                                            name: 'imei' + row.item_id
                                                        },
                                                        {
                                                            label: 'SIM',
                                                            size: 10,
                                                            labelSize: 4,
                                                            name: 'sim' + row.item_id
                                                        },
                                                        {
                                                            label: 'Line Instructions:',
                                                            type: 'textarea',
                                                            size: 10,
                                                            labelSize: 4,
                                                            name: 'lineInstructions' + row.item_id
                                                        }
                                                    ]}/>
                                            </Form>
                                        }
                                    />,
                                    center: true,
                                    maxWidth: '13%'
                                },
                                {
                                    name: 'Port In',
                                    selector: (row) => row.port_in,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'Phone #',
                                    selector: (row) => row.phone_number,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'User name',
                                    selector: (row) => row.user_name,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'Contract',
                                    selector: (row) => row.contract,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'Rate plan',
                                    selector: (row) => row.rate_plan,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'Make / Model',
                                    selector: (row) => row.make,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'Monthly Cost',
                                    selector: (row) => row.monthly_cost,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'SIM',
                                    selector: (row) => row.sim,
                                    sortable: true,
                                    center: true
                                },
                                {
                                    name: 'Line Instructions',
                                    selector: (row) => row.line_instructions ? <span title={row.line_instructions}>NOTES</span> : '',
                                    sortable: true,
                                    center: true
                                }
                            ]}
                        />
                </CardBody>
            </Card>
            </Fragment>
        );
    } else return <p>Loading Order...</p>
};

export default ItemEntry;
