import { Container } from 'reactstrap';
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { H4, P } from '../../../AbstractElements';
import { ApiRequestBuilder, TilesBuilder } from '../../../GlobalComponents/Builders';
import { Link } from 'react-router-dom';
import { AccountContext } from '../../../GlobalComponents/Authentication/Accounts';
import moment from 'moment';


const Dashboard = () => {

    const [availableTools, setAvailableTools] = useState(null)
    const [subscriptions, setSubscriptions] = useState(null)

    const [tileData, setTileData] = useState(null)
    const [tileDataTools, setTileDataTools] = useState(null)

    const { userSession } = useContext(AccountContext);

    const getTools = () => {
        ApiRequestBuilder('toolsGet').then(function (results) {
            if (results) {
                setAvailableTools(results.data)
            }
        })
    }

    const getPartnerSubscriptions = () => {
        ApiRequestBuilder('subscriptionsGet').then(function (results) {
            setSubscriptions(results.data.filter(element => element.partner_id == userSession['custom:partnerId'] && element.is_active))
        })
    }

    const buildSubscribedToTiles = () => {
        const tools = subscriptions.map(element => element.tool_id)

        setTileData(subscriptions.length > 0 && subscriptions.map(sub => {
                const date = moment(new Date(sub['subscribed_at'] * 1000))
                const now = moment(new Date())
                const footNote = now.weeks() === date.weeks() ? 'this week' : now.month() === date.month() ? 'this month' : 'in ' + date.format('MMMM YYYY')
                var toolToRoute = availableTools.filter(element => element.tool_id === sub.tool_id)
                return {
                    title: sub.tool_name,
                    link: `${process.env.PUBLIC_URL}/tools/${toolToRoute.length > 0 ? toolToRoute[0]['route'] : ''}`,
                    icon: 'Tool',
                    backgroundIcon: 'Database',
                    footNote: `Subscribed ${footNote}`
                }
            })
        )
        return tools
    }

    const buildAvailableTiles = (tools) => {
        setTileDataTools(availableTools.filter(tool => !tools.includes(tool.tool_id)).map(sub => {
            return {
                title: sub.name,
                link: `${process.env.PUBLIC_URL}/tools/${sub.route}`,
                icon: 'Database',
            }
        })
        )
    }

    useEffect(() => {

        document.title = 'View Dashboard'

        getTools()

        getPartnerSubscriptions()
    }, []);

    useEffect(() => {

        if (subscriptions && availableTools) {
            buildAvailableTiles(buildSubscribedToTiles())
        }
    }, [subscriptions, availableTools])

    return (
        <Fragment>
            <Container fluid={true}>
                <H4>Welcome! </H4>
                {tileDataTools &&
                    <>
                        {tileData.length > 0 &&
                            <>
                                <br />
                                <P>Use these quick-links to get right to work</P>
                                <br />
                                <TilesBuilder data={tileData} />
                                <br />
                            </>
                        }
                        {tileDataTools.length > 0 &&
                            <>
                                <br />
                                <P>{tileData.length > 0 ? `Don't forget to check out our other available tools! ` :
                                    `Check out the tools our portal offers! Contact your NexNow representative and `}
                                    <Link to={`${process.env.PUBLIC_URL}/help`}>Request access here</Link>
                                </P>
                                <br />
                                <TilesBuilder data={tileDataTools} />
                            </>
                        }
                    </>
                }
            </Container>
        </Fragment>
    );
};

export default Dashboard;